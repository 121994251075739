import MD5 from "crypto-js/md5";

export const vue_methods = {
    decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    },
    t(toTranslate) {
        if (typeof window.translations[MD5(toTranslate)] !== "undefined") {
            return window.translations[MD5(toTranslate)];
        }
        return toTranslate;
    },
    travel_dates_str() {
        return this.t('Bet kada');
    },
    nights_str(from,to) {
        let text = this.t('Naktys');
        if(parseInt(from) > 0) {
            text = from + '-' + to + " " + this.str_by_number(parseInt(to), [this.t('naktis'), this.t('naktys'), this.t('naktų')]);
            if (from === to) {
                text = from + " " + this.str_by_number(parseInt(from), [this.t('naktis'), this.t('naktys'), this.t('naktų')]);
            }
        }
        return text;
    },
    passengers_str(adults, children) {
            let text = this.t('Keleiviai');
            if(adults.length > 0) {
                text = adults + " " + this.str_by_number(parseInt(adults), [this.t('suaugęs'), this.t('suaugę'), this.t('suaugusių')]);
                if (parseInt(children) > 0) {
                    text = text + ", " + children + " " + this.str_by_number(parseInt(children), [this.t('vaikas'), this.t('vaikai'), this.t('vaikų')]);
                }
            }
        return text;
    },
    str_by_number(number,names) {
        let mod = number % 100;

        let name = names[1];
        if(mod===1) {
            name = names[0];
        } else if(mod === 0 || (mod >= 10 && mod <= 20) || mod === 30 || mod === 40 || mod === 50 || mod === 60) {
            name = names[2];
        }
        return name;
    }
};