<template>
  <div class="destination-inquiry-wrp">
    <div class="destinations-options-search-wrp">
      <div id="destinationField" class="fake-field" :class="has_error ? 'is-invalid' : ''">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input type="text" name="destination-search-field" :placeholder="t('Ieškoti šalies ar miesto')" v-model="searchString" ref="destinationSearchField" @input="this.noResults()"/>
      </div>
      <span class="error-message">{{ t('Pasirinkite bent vieną kelionės tikslą.') }}</span>
      <div class="custom-dropdown" :class="[searchString.length > 0 ? 'active' : '', this.getDropdownClass()]">
        <div class="inner-wrp custom-scrollbar">
          <div class="destination-options-wrp" ref="destinations_items">
            <inquiry-single-destination v-for="(category, key) in categories" :destination="category" :parent="false" :search="searchString"></inquiry-single-destination>
            <div class="no-results" v-show="showNoResults">{{ t('Nėra rezultatų.') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="selected-destinations-wrp mt-14" v-if="selected_categories.length > 0">
      <div class="selected-destination-item" v-for="(c_key) in selected_categories" @click="removeDestination(c_key)">
        <input type="hidden" name="categories[]" :value="c_key" />
        {{ this.$refs.destinations_items.querySelector('.destination-item[data-id="'+ c_key +'"]').textContent }}
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
  </div>
</template>

<script>
import InquirySingleDestination from "./inquirySingleDestination.vue";

export default {
  components: {InquirySingleDestination},
  props: ['has_error'],
  data() {
    return {
      selected_categories: [],
      searchString: '',
      searchField: null,
      showNoResults: false,
      categories: window.inquiry_categories
    }
  },
  mounted() {
    this.selected_categories = window.selected_categories;
    this.searchField = document.getElementById('destinationField');
  },
  methods: {
    noResults() {
      let component = this;
      if (component.searchField !== null) {
        let counter = component.searchField.parentNode.querySelectorAll('.destination-item:not([style="display: none;"])').length;

        if (counter > 0) {
          component.showNoResults = false;
        } else {
          component.showNoResults = true;
        }
      } else {
        component.showNoResults = false;
      }
    },
    getDropdownClass() {
      let component = this;
      if (component.searchField !== null) {
        let offset = this.searchField.getBoundingClientRect();
        let field_height = component.searchField.offsetHeight;
        let window_height = window.screen.availHeight;

        let space_top = offset.top;
        let space_bottom = window_height - (offset.top + field_height);

        if (space_top > space_bottom) {
          return 'top';
        } else {
          return 'bottom';
        }
      }
    },
    addDestination(id) {
      if (!this.selected_categories.includes(id)) {
        this.selected_categories.push(id);
      }

      this.searchString = '';
      this.$refs.destinationSearchField.focus();
      this.$refs.destinationSearchField.parentNode.classList.remove('is-invalid');
    },
    removeDestination(id) {
      this.selected_categories.splice(this.selected_categories.indexOf(id), 1);
    }
  }
}
</script>