<template>
  <div class="product-search-part">
    <h2 class="block-title h3">{{ t('Kainos paieška') }}</h2>
    <div class="search-filters-wrp">
      <div class="search-form-wrp">
        <div class="filters-listing" v-show="!loading">
          <div class="inner-wrp">
            <div class="filter-group-wrp dates ajax" :class="{'open':activeVariable==='dates'}"
                 v-click-outside="closeDates" >
              <h3 class="filter-group-title" @click="toggleSearchDropdown('dates')">{{ travel.activeDateText.length>0?travel.activeDateText:t('Bet kada') }}</h3>
              <div class="filter-group-backdrop backdrop" @click="toggleSearchDropdown('dates')"></div>
              <div class="filter-group-items dates-group">
                <div class="filter-group-items-header">
                  <span class="title">{{ travel.activeDateText.length>0?travel.activeDateText:t('Bet kada') }}</span>
                  <button class="btn-close" type="button" @click="toggleSearchDropdown('dates')"></button>
                </div>
                <div class="inner-wrp custom-scrollbar">
                  <div class="d-none d-lg-block">
                    <dates-content resolution="desktop"></dates-content>
                  </div>
                  <div class="d-block d-lg-none">
                    <dates-content resolution="mobile"></dates-content>
                  </div>
                </div>
                <div class="filter-group-items-footer">
                  <button class="btn btn-primary w-100" @click="toggleSearchDropdown('dates')">{{ t('Išsaugoti') }}</button>
                </div>
              </div>
            </div>
            <div class="filter-group-wrp passengers ajax" :class="{'open':activeVariable==='passengers'}"
                 v-click-outside="closePassengers" >
              <h3 class="filter-group-title" @click="toggleSearchDropdown('passengers')">{{ passengers_str(travel.adults, travel.children) }}</h3>
              <div class="filter-group-backdrop backdrop" @click="toggleSearchDropdown('passengers')"></div>
              <div class="filter-group-items">
                <div class="filter-group-items-header">
                  <span class="title">{{ passengers_str(travel.adults, travel.children) }}</span>
                  <button class="btn-close" type="button" @click="toggleSearchDropdown('passengers')"></button>
                </div>
                <div class="inner-wrp custom-scrollbar">
                  <div class="">
                    <passengers-content></passengers-content>
                  </div>
                </div>
                <div class="filter-group-items-footer">
                  <button class="btn btn-primary w-100" @click="toggleSearchDropdown('passengers')">{{ t('Išsaugoti') }}</button>
                </div>
              </div>
            </div>
            <div class="filter-group-wrp nights ajax" :class="{'open':activeVariable==='nights'}"
                 v-click-outside="closeNights" >
              <h3 class="filter-group-title" @click="toggleSearchDropdown('nights')">{{ nights_str(travel.duration.from,travel.duration.to) }}</h3>
              <div class="filter-group-backdrop backdrop" @click="toggleSearchDropdown('nights')"></div>
              <div class="filter-group-items night-group">
                <div class="filter-group-items-header">
                  <span class="title">{{ nights_str(travel.duration.from,travel.duration.to) }}</span>
                  <button class="btn-close" type="button" @click="toggleSearchDropdown('nights')"></button>
                </div>
                <div class="inner-wrp custom-scrollbar">
                  <div class="">
                    <nights-content></nights-content>
                  </div>
                </div>
                <div class="filter-group-items-footer">
                  <button class="btn btn-primary w-100" @click="toggleSearchDropdown('nights')">{{ t('Išsaugoti') }}</button>
                </div>
              </div>
            </div>
            <div class="filter-group-wrp ajax" :class="{'open':openedFilter===1}"
                 v-clickOutside:[1]="closeFilter" v-show="pansions.length>0">
              <h3 class="filter-group-title" @click="openFilter(1)">{{ t('Maitinimas') }}</h3>
              <div class="filter-group-backdrop backdrop" @click="closeFilter(1)"></div>
              <div class="filter-group-items">
                <div class="filter-group-items-header">
                  <span class="title">{{ t('Maitinimas') }}</span>
                  <button class="btn-close" type="button" @click="closeFilter(1)"></button>
                </div>
                <div class="inner-wrp custom-scrollbar">
                  <ul>
                    <li v-for="(pansion,key) in pansions" class="widget-filter-item"
                        :class="{active:selectedPansions.includes(pansion)}"
                    >
                      <div class="filter-check mb-0">
                        <label class="form-check-label widget-filter-item-text" :for="'pansion_'+key">
                          <input class="form-check-input" type="checkbox" :id="'pansion_'+key"
                               v-model="selectedPansions" :value="pansion">
                          {{ pansion }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="filter-group-items-footer">
                  <button class="btn btn-primary w-100" @click="closeFilter(1)">{{ t('Išsaugoti') }}</button>
                </div>
              </div>
            </div>
            <div class="filter-group-wrp ajax" :class="{'open':openedFilter===2}"
                 v-clickOutside:[2]="closeFilter" v-show="rooms.length>0">
              <h3 class="filter-group-title" @click="openFilter(2)">{{ t('Kambario tipas') }}</h3>
              <div class="filter-group-backdrop backdrop" @click="closeFilter(2)"></div>
              <div class="filter-group-items">
                <div class="filter-group-items-header">
                  <span class="title">{{ t('Kambario tipas') }}</span>
                  <button class="btn-close" type="button" @click="closeFilter(2)"></button>
                </div>
                <div class="inner-wrp custom-scrollbar">
                  <ul>
                    <li v-for="(room,key) in rooms" class="widget-filter-item"
                        :class="{active:selectedRooms.includes(room)}"
                    >
                      <div class="filter-check mb-0">
                        <label class="form-check-label widget-filter-item-text" :for="'room_'+key">
                          <input class="form-check-input" type="checkbox" :id="'room_'+key"
                               v-model="selectedRooms" :value="room">
                          {{ room }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="filter-group-items-footer">
                  <button class="btn btn-primary w-100" @click="closeFilter(2)">{{ t('Išsaugoti') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filters-loading" v-show="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="filters-wrp" v-if="selectedPansions.length > 0 || selectedRooms.length > 0">
        <button v-for="pansion in selectedPansions"
                class="btn btn-filter btn-sm remove_filter_value"
                @click="removePansion(pansion)"
        >
          {{ pansion }} <i class="fa-regular fa-xmark"></i>
        </button>
        <button v-for="room in selectedRooms"
                class="btn btn-filter btn-sm remove_filter_value"
                @click="removeRoom(room)"
        >
          {{ room }} <i class="fa-regular fa-xmark"></i>
        </button>
        <button class="btn btn-filter btn-link clean_all" :title="t('Atstatyti filtrus')" @click="clearAll()"
                v-show="selectedPansions.length > 0 || selectedRooms.length>0">
          {{t('Atstatyti filtrus')}}
        </button>
      </div>
    </div>
    <div v-show="loading" class="offers-loading-wrp">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="offers-wrp">
      <div class="offers-listing" v-show="!loading && offers.length > 0">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div v-for="offer in offers" v-show="typeof offer.hide === 'undefined' || !offer.hide" class="swiper-slide">
              <offer :offer="offer" :cheapestPrice="cheapestPrice" @offer:selected="offerSelected" :productId="product.id"></offer>
            </div>
          </div>
        </div>

        <div class="swiper-nav-btn swiper-button-next"><i class="fa-regular fa-arrow-right-long"></i></div>
        <div class="swiper-nav-btn swiper-button-prev"><i class="fa-regular fa-arrow-left-long"></i></div>
      </div>

      <div class="no-offers-wrp" v-show="!loading && offers.length === 0">
        <div class="inner-wrp">
          <span class="title">{{t('Tikslių atitikmenų nėra')}}</span>
          <span class="desc">{{t('Pabandykite pakeisti arba pašalinti kai kuriuos filtrus')}}</span>

<!--          <button class="btn btn-outline-t-secondary clean_all mt-16 mt-lg-16" :title="t('Atstatyti filtrus')" @click="clearAll()">-->
<!--            {{t('Atstatyti filtrus')}}-->
<!--          </button>-->
        </div>
      </div>

      <div class="offers-warnings">
        <ul>
          <li>{{ t('Kainos yra dinaminės ir gali pasikeisti.') }}</li>
          <li>{{ t('Vietų skaičius už nurodytą kainą yra ribotas!') }}</li>
          <li>{{ t('Rezervacija yra tvirtinama tik po atlikto apmokėjimo.') }}</li>
          <li>
            {{ t('Rezervacijai patvirtinti reikalingi visų keliaujančiųjų asmenų vardai, pavardės bei gimimo datos.') }}
          </li>
          <li>
            {{ t('Išvykimo metu būtina turėti pasą, kuris galioja ne mažiau nei 6 mėn. po grįžimo iš kelionės arba ID kortelę keliaujantiems į ES šalis.') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <offer-modal :offer="selectedOffer" :product="product"></offer-modal>
</template>
<script>
import Offer from "./inner/offer.vue";
import OfferModal from "./inner/offerModal.vue";
import {travel} from "./reactives/travel";
import moment from "moment/moment";
import Swiper from "swiper";
import PassengersContent from "./travel/passengersContent.vue";
import NightsContent from "./travel/nightsContent.vue";

export default {
  components: {NightsContent, PassengersContent, Offer,OfferModal},
  props: [],
  data() {
    return {
      travel,
      offers: [],
      pids: [],
      loading: false,
      pansions: [],
      rooms: [],
      checkInProgress: false,
      processes: [],
      cheapestPrice: false,
      activeSearches: 0,
      selectedPansions:[],
      selectedRooms:[],
      openedFilter: null,
      selectedOffer: {},
      product: window.product,
      activeVariable: false,
      currentVal: false,
      initial: true,
    }
  },
  watch: {
    activeSearches(newVal, oldVal) {
      if (oldVal > 0 && newVal < 1) {
        this.loading = false;
        this.initial =false;
        this.sortOptions();
      }
    }
  },
  mounted() {
    let component = this;
    setTimeout(function () {
      component.checkProcesses();
    }, 1000);
    if (typeof window.travel !== 'undefined' && Object.keys(window.travel).length > 0) {
      Object.keys(window.travel).forEach(function (index) {
        if(index === 'date') {
          if(typeof window.travel[index]['from'] !== 'undefined') {
            component.travel[index] = window.travel[index];
          } else {
            component.travel[index]['from'] = window.travel[index];
            component.travel[index]['to'] = window.travel[index];
          }
        } else {
          component.travel[index] = window.travel[index];
        }
      });
      if(component.travel['date']['from'].length > 0) {
        if(component.travel['tab'] === 'month') {
          moment.locale('lt');
          component.travel.activeDateText = moment(component.travel['date']['from']).format('YYYY MMMM');
          if(moment(component.travel['date']['from']).format('MM') !== moment(component.travel['date']['to']).format('MM')) {
            component.travel.activeDateText += ' - ' +  moment(component.travel['date']['to']).format('YYYY MMMM');
          }
        } else {
          component.travel.activeDateText = moment(component.travel['date']['from']).format('YYYY-MM-DD');
          if(component.travel['date']['from'] !== component.travel['date']['to']) {
            component.travel.activeDateText += ' - ' +  moment(component.travel['date']['to']).format('YYYY-MM-DD');
          }
        }
      }
    }
    this.getOffers();
  },
  methods: {
    closeDropdown(type) {
      if (this.activeVariable === type) {
        this.activeVariable = false;
        if (JSON.stringify(this.travel) !== this.currentVal) {
          this.getOffers();
        }
      }
    },
    closeDates() {
      this.closeDropdown('dates');
    },
    closeNights() {
      this.closeDropdown('nights');
    },
    closePassengers() {
      this.closeDropdown('passengers');
    },
    toggleSearchDropdown(type) {
      if (this.activeVariable === type) {
        this.closeDropdown(type);
      } else {
        this.activeVariable = type;
        this.currentVal = JSON.stringify(this.travel);
      }
    },
    checkProcesses() {
      let component = this;
      setTimeout(function () {
        component.checkProcesses();
      }, 1000);
      if(component.checkInProgress) {
        return;
      }
      component.checkInProgress = true;
      if (component.processes.length > 0) {
        for (let [index, process] of component.processes.entries()) {
          if (component.processes[index].status === 'done') {
            component.processes.splice(index, 1);
          }
        }
        ;
        component.processes.forEach(function (process, index) {
          if (process.status === 'new') {
            process.status = 'checking';
            $.ajax({
              type: 'GET',
              url: '?display=content_types/products/ajax_travel_options.tpl',
              data: {
                operator: process.operator,
                product: component.product.id,
                travel: component.travel.prepareForRequest(),
                last_minute: component.product.last_minute,
                pid: process.pid
              },
              success: function (data) {
                if (typeof data.background !== "undefined") {
                  process.status = 'new';
                } else {
                  process.status = 'done';
                  if(typeof data.options !== "undefined" && data.options.length > 0) {
                    let optionsAdded = 0;
                    data.options.forEach(function (option,index,arr) {
                      component.addOption(option);
                      optionsAdded++;
                      if(optionsAdded === arr.length) {
                        component.activeSearches--;
                      }
                    });
                  } else {
                    component.activeSearches--;
                  }
                }
              }
            });
          }
        });
      }
      component.checkInProgress = false;
    },
    getOffers() {
      let component = this;
      component.offers = [];
      component.pansions = [];
      component.rooms = [];
      component.selectedPansions = [];
      component.selectedRooms = [];
      component.loading = true;
      component.activeSearches++;
      if(!this.initial) {
        history.pushState(
            {},
            null,
            '?' + decodeURIComponent($.param({
              travel: component.travel.prepareForRequest()
            }))
        )
      }
      $.ajax({
        type: 'GET',
        url: '?display=content_types/products/ajax_travel_options.tpl',
        data: {
          product: component.product.id,
          last_minute: component.product.last_minute,
          travel: component.travel.prepareForRequest(),
        },
        success: function (data) {
          data.forEach(function (operator) {
            if (typeof operator.pids !== "undefined") {
              component.activeSearches++;
              operator.pids.forEach(function (pid) {
                component.processes.push({
                  'pid': pid,
                  'operator': operator.operator,
                  'status': 'new'
                });
              });
            }
            if (typeof operator.options !== "undefined") {
              operator.options.forEach(function (option) {
                component.addOption(option);
              });
            }
          });
          component.activeSearches--;
        }
      });
    },
    addOption(option) {
      let component = this;
      if(typeof option.price === 'undefined' || option.price < 1) {
        return false;
      }
      if(component.cheapestPrice === false || component.cheapestPrice > option.price) {
        component.cheapestPrice = option.price;
      }
      if(!component.pansions.includes(option.pansion.name)) {
        component.pansions.push(option.pansion.name);
      }
      if(!component.rooms.includes(option.hotel_room_name)) {
        component.rooms.push(option.hotel_room_name);
      }
      component.offers.push(option);
    },
    async sortOptions() {
      let component = this;
      await component.offers.sort(function (a, b) {
        return a.price - b.price;
      });
      if(component.offers.length > 50) {
        let c = 1;
        component.offers.forEach(function (offer) {
          if(c++ >= 50) {
            offer.hide = true;
          }
        });
      }
      component.swiperInit();
    },
    submitFilters() {
      let component = this;
      let c = 0;
      component.offers.forEach(function (offer) {
        if((component.selectedRooms.includes(offer.hotel_room_name) || component.selectedRooms.length === 0)
        && (component.selectedPansions.includes(offer.pansion.name) || component.selectedPansions.length === 0)) {
          offer.hide = false;
          c++;
        }else {
          offer.hide = true;
        }
        if(c >= 50) {
          offer.hide = true;
        }
      });
      component.swiperInit();
    },
    openFilter(id) {
      if (this.openedFilter === id) {
        this.closeFilter(id);
      } else {
        this.openedFilter = id;
      }
    },
    closeFilter(id) {
      if (this.openedFilter === id) {
        this.submitFilters();
        this.openedFilter = 0;
      }
    },
    removePansion(pansion) {
      this.selectedPansions.splice(this.selectedPansions.indexOf(pansion), 1);
      this.submitFilters();
    },
    removeRoom(room) {
      this.selectedRooms.splice(this.selectedRooms.indexOf(room), 1);
      this.submitFilters();
    },
    clearAll() {
      this.selectedPansions = [];
      this.selectedRooms = [];
      this.submitFilters();
    },
    swiperInit() {
      let swiper = new Swiper('.offers-listing .swiper', {
        slidesPerView: 1.2,
        spaceBetween: 12,
        navigation: {
          enabled: false,
          nextEl: '.offers-listing .swiper-button-next',
          prevEl: '.offers-listing .swiper-button-prev',
        },
        breakpoints: {
          767: {
            slidesPerView: 2.2,
            spaceBetween: 14,
            navigation: {
              enabled: false,
            },
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
            navigation: {
              enabled: true,
            },
          }
        }
      });
    },
    offerSelected(offer) {
      this.selectedOffer = offer;
      // this.$nextTick(() => {
      //   $('#offerDetailsModal').modal('show');
      // });
    },
    loadFlightData(offer) {
      this.selectedOffer = offer;
    }
  }
}
</script>

