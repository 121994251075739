<template>
  <div class="destination-item" :data-id="destination.id" v-show="isVisible" @click="addDestination(destination.id)">
    {{ destination.name }}<template v-if="parent">, {{ parent.name }}</template>
  </div>

  <template v-if="destination.childs">
    <inquiry-single-destination  v-for="(child, c_key2) in destination.childs" :destination="child" :parent="destination" :search="search"></inquiry-single-destination>
  </template>
</template>

<script>
export default {
  components: {},
  props: ['destination', 'parent', 'search'],
  data() {
    return {
      isVisible: false,
    }
  },
  watch: {
    search(newVal, OldVal) {
      if (newVal.length > 0 ) {
        if ((this.destination.name.toLowerCase().includes(newVal.toLowerCase())) || (this.parent && this.parent.name.toLowerCase().includes(newVal.toLowerCase()))) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      } else {
        this.isVisible = false;
      }
    }
  },
  methods: {
    addDestination(id) {
      this.$parent.addDestination(id);
    }
  }
}
</script>