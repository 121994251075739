<template>
  <div class="menu-desk-backdrop backdrop"></div>
  <div v-if="menuItems.length > 0"
       id="mainMenuMega"
       class="main-menu-container"
      :class="{'mega-active': megaOpen}"
      @mouseleave="closeMegaMenu"
      ref="mainMenu">
    <ul>
      <li v-for="(menuItem, index) in menuItems">
        <div class="menu-section">
          <!-- first level items -->
          <a v-if="menuItem.link"
             :href="menuItem.full_url"
             @mouseenter="menuItem.items.length > 0 ? openMegaMenu(index) : closeMegaMenu()"
             @touchstart="menuItem.items.length > 0 ? openMegaMenu(index) : closeMegaMenu()"
             class="menu-item" data-lvl="1"
             :class="[megaActiveIndex === index ? 'active' : '', menuItem.items.length > 0 ? 'has-childs' : '']">
            {{ menuItem.name }}
          </a>
          <span v-else
                role="button"
                @mouseenter="menuItem.items.length > 0 ? openMegaMenu(index) : closeMegaMenu()"
                @touchstart="menuItem.items.length > 0 ? openMegaMenu(index) : closeMegaMenu()"
                class="menu-item" data-lvl="1"
                :class="[megaActiveIndex === index ? 'active' : '', menuItem.items.length > 0 ? 'has-childs' : '']">
              {{ menuItem.name }}
          </span>

          <div v-if="menuItem.items.length" class="dropdown-content-wrp"
               :data-index="index"
               :class="[megaActiveIndex === index ? 'open' : '', inAction ? 'in-action' : '']">
            <div class="content-grid">
              <div class="dropdown-content-inner-wrp">

                <template v-for="(subitem, s_index) in menuItem.items">
                  <div class="mega-column-wrp" :class="'columns-' + subitem.item_group_chunks_cnt">
                    <div class="menu-group-wrp">
                      <div class="group-title-wrp">
                        <a v-if="subitem.link" :href="subitem.full_url" class="group-title">{{ subitem.name }}</a>
                        <span v-else class="group-title">{{ subitem.name }}</span>
                      </div>
                      <div class="group-items-wrp" v-if="subitem.items.length > 0">
                        <ul v-for="(items_group, g_index) in subitem.item_groups">
                          <li v-for="(child, c_index) in items_group">
                            <a :href="child.full_url">
                              <img v-if="child.before_icon" height="22px" :src="child.before_icon.src" class="item-icon icon-before">
                              <span>
                                {{ child.name }}
                              </span>
                              <img v-if="child.after_icon" height="22px" :src="child.after_icon.src" class="item-icon icon-after">
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </template>

              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>


export default {
  props: [],
  data() {
    return {
      mainHeader: false,
      backdrop: false,
      inAction: false,
      megaOpen: false,
      megaActiveIndex: false,
      menuItems: window.main_menu.menu_items,
    }
  },
  mounted() {
    this.mainHeader = document.getElementById("mainHeader");
    this.backdrop = document.querySelector('.menu-desk-backdrop');

    document.addEventListener('click', this.handleClickOutside);

    // split lvl 3 items into max 9 items per column
    this.menuItems.forEach(menu => {
      let per_column = 9;

      if (menu.items.length > 0) {
        menu.items.forEach(menuItem => {
          if (menuItem.items.length > 0) {
            menuItem.item_group_chunks_cnt = 0;
            let result = [];

            for (let i = 0; i < menuItem.items.length; i += per_column) {
              let chunk = menuItem.items.slice(i, i + per_column);
              result.push(chunk);
              menuItem.item_group_chunks_cnt++;
            }

            menuItem.item_groups = result;
          }
        })
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    openMegaMenu: function(index) {
      let component = this;
      component.mainHeader.classList.add('mega-active');
      component.backdrop.classList.add('active');
      // document.querySelector('body').classList.add('locked');

      // document.getElementById('mainHeader').classList.add('menu-active');

      if (!component.megaOpen) {
        component.inAction = true;
        component.megaOpen = true;
        setTimeout(function() {
          component.inAction = false;
        }, 200);
      }
      component.megaActiveIndex = index;
    },

    closeMegaMenu: function() {
      let component = this;

      if (component.megaOpen) {
        component.mainHeader.classList.remove('mega-active');
        component.backdrop.classList.remove('active');
        // document.querySelector('body').classList.remove('locked');
        component.megaOpen = false;
        component.megaActiveIndex = false;
        component.inAction = true;
        // document.getElementById('mainHeader').classList.remove('menu-active');

        setTimeout(function() {
          component.inAction = false;

        }, 200);
      }
    },

    handleClickOutside: function(event) {
      if (!this.$refs.mainMenu.contains(event.target)) {
        this.closeMegaMenu();
      }
    }
  }
}
</script>