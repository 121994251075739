<template>
  <div class="inner-menu-group-wrp" v-if="menu_item.items.length" :data-submenu-id="menu_item.id" :data-parent="parent">
    <div class="inner-menu-group-header">
      <span class="inner-menu-group-title">{{ menu_item.name }}</span>
    </div>

    <div v-if="menu_item.custom_block && menu_item.custom_block_link" class="custom-mob-block">
      <span v-if="menu_item.custom_block_text">{{ menu_item.custom_block_text }}</span>
      <a :href="menu_item.custom_block_link" class="btn btn-primary">
        <template v-if="menu_item.custom_block_btn_text">
          {{ menu_item.custom_block_btn_text }}
        </template>
        <template v-else>
          {{ t('Žiūrėti viską') }}
        </template>
        <i class="fa fa-chevron-right ms-3 mb-n1"></i>
      </a>
    </div>

    <div class="inner-menu-group-items-wrp">
      <div class="inner-menu-group-item" v-for="(subitem, index) in menu_item.items">
        <a v-if="!subitem.items.length" :href="subitem.full_url" class="menu-item link" :data-lvl="lvl">
          <img v-if="subitem.before_icon" height="22px" :src="subitem.before_icon.src" class="item-icon icon-before">
          {{ subitem.name }}
          <img v-if="subitem.after_icon" height="22px" :src="subitem.after_icon.src" class="item-icon icon-after">
        </a>
        <button v-else class="menu-item button act-open-submenu" :data-title="subitem.name" :data-id="subitem.id" :data-lvl="lvl">
          {{ subitem.name }}
        </button>
      </div>
      <div class="inner-menu-group-item" v-if="lvl == 3 && menu_item.link">
        <a :href="menu_item.full_url" class="menu-item link" :data-lvl="lvl">
          {{ t('Žiūrėti viską') }}
        </a>
      </div>
    </div>
  </div>

  <template v-for="(subitem, index) in menu_item.items">
    <main-menu-drawer-inner-group :menu_item="subitem" :lvl="3" :parent="menu_item.id"></main-menu-drawer-inner-group>
  </template>
</template>

<script>

export default {
  name: "MainMenuDrawerInnerGroup",
  props: ['menu_item', 'lvl', 'parent'],
  data() {
    return {
      // menu_item: false
    }
  },
  mounted() {
    // console.log(this.menu_item);
  },
  methods: {}
}
</script>