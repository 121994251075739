<template>
  <!-- Products grid-->
  <div id="products-list">
    <div v-if="!loading || products.length > 0" class="products-grid">
      <product v-for="product in products" v-show="product.visible && product.filtered" :product="product"></product>
    </div>
    <div v-if="loading && products.length === 0" class="products-grid">
      <div class="product-placeholder" v-for="i in 12">
        <span class="photo"></span>
        <div class="texts-wrp">
          <span class="location"></span>
          <span class="title"></span>
          <span class="dates"></span>
          <span class="price"></span>
        </div>
      </div>
    </div>
    <div class="no-offers-wrp" v-show="!loading && products.length === 0">
      <div class="inner-wrp">
        <span class="title">{{t('Tikslių atitikmenų nėra')}}</span>
        <span class="desc">{{t('Pabandykite pakeisti arba pašalinti kai kuriuos filtrus')}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['title', 'products', 'loading'],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>
