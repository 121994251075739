<template>
  <select :placeholder="name" class="form-control border"
          v-model="tempValue" multiple>
    <option :value="id" v-for="(option,id) in options"
            :selected="id === modelValue || (Array.isArray(modelValue) && modelValue.includes(id))">{{ option }}
    </option>
  </select>
</template>

<script>
export default {
  props: ['options', 'modelValue', 'name'],
  data() {
    return {
      tempValue: []
    }
  },
  watch: {
    tempValue(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  mounted() {
    $(this.$el).selectpicker({})
  },
  updated: function () {
    $(this.$el).selectpicker('destroy');
    $(this.$el).selectpicker({});
    $(this.$el).selectpicker('val', this.modelValue);
  },
}
</script>
