<template>
  <div class="page-title-block">
    <h1 class="page-title">{{ title }}</h1>
    <div class="top-description-wrp" v-if="description" v-html="description"></div>
<!--    {block name="description"}-->
<!--    {if empty($frontend->collection) && empty($frontend->last_category) &&-->
<!--    empty($frontend->brand) && empty($frontend->category)}-->
<!--    <div class="top-description-wrp">{$h->display_html($frontend->page.content)}</div>-->
<!--    {/if}-->
<!--    {if !empty($frontend->collection) && $frontend->collection.description}-->
<!--    <div class="top-description-wrp">{$h->display_html($frontend->collection.description)}</div>-->
<!--    {elseif !empty($frontend->last_category) && $frontend->last_category.description}-->
<!--    <div class="top-description-wrp">{$h->display_html($frontend->last_category.description)}</div>-->
<!--    {elseif !empty($frontend->category) && $frontend->category.description}-->
<!--    <div class="top-description-wrp">{$h->display_html($frontend->category.description)}</div>-->
<!--    {elseif !empty($frontend->brand) && $frontend->brand.description}-->
<!--    <div class="top-description-wrp">{$h->display_html($frontend->brand.description)}</div>-->
<!--    {/if}-->

<!--    {if !empty($frontend->collection) && $frontend->collection.photos_count}-->
<!--    <div class="banner text-center">-->
<!--      <img class="img-fluid center-block" src="{$frontend->collection.photo.src}" />-->
<!--    </div>-->
<!--    {elseif !empty($frontend->last_category.photos_count)}-->
<!--    <div class="banner text-center">-->
<!--      <img class="img-fluid center-block" src="{$frontend->last_category.photo.src}" />-->
<!--    </div>-->
<!--    {/if}-->
  </div>
</template>

<script>
export default {
  props: ['title','description'],
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
