<template>
  <div class="menu-mob-backdrop backdrop"></div>
  <div id="mainMenuMob" role="dialog" aria-modal="true">
    <div class="inner-wrp" tabindex="-1" role="presentation">
      <div class="header-wrp">
        <div class="action-wrp">
          <button class="btn-return act-close-submenu d-none">
            <i class="fa-regular fa-chevron-left"></i>
            {{ t('Atgal') }}
          </button>
        </div>
        <button class="menu-close-btn" :aria:lavel="t('Uždaryti')">
          <i class="fa-regular fa-xmark"></i>
        </button>
      </div>

      <div class="content-wrp">
        <div class="menu-groups-wrp" v-if="mainMenu.menu_items">
          <div class="menu-group-wrp" v-for="(menu_item, index) in mainMenu.menu_items">
            <a v-if="!menu_item.items.length" :href="menu_item.full_url" class="menu-item link" data-lvl="1">
              {{ menu_item.name }}
            </a>
            <button v-else class="menu-item button act-open-submenu" :data-title="menu_item.name" :data-id="menu_item.id" data-lvl="1">
              {{ menu_item.name }}
            </button>

            <main-menu-drawer-inner-group :menu_item="menu_item" :lvl="2" :parent="0"></main-menu-drawer-inner-group>
          </div>
        </div>

        <div class="bottom-content-wrp">
<!--          <div class="fake-help"></div>-->
          <help-block ></help-block>
<!--          {{$frontend->view('pages/help')}}-->

          <ul class="additional-menu-items-wrp">
            <li>
              <a :href="inquiriesUrl">
                <i class="fa-regular fa-bolt"></i> {{ t('Greita užklausa') }}
              </a>
            </li>
<!--            <li>
              <a href="/">
                <i class="fa-regular fa-heart"></i>{{ t('Mėgstamiausieji') }}
              </a>
            </li>-->
<!--            <li>
              <a href="/">
                <i class="fa-regular fa-circle-question"></i>{{ t('Pagalba') }}
              </a>
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MainMenuDrawerInnerGroup from "./mainMenuDrawerInnerGroup.vue";
import HelpBlock from "./helpBlock.vue";

export default {
  components: {HelpBlock, MainMenuDrawerInnerGroup},
  props: [],
  data() {
    return {
      mainMenu: window.main_menu,
      inquiriesUrl: window.inquiry_page_url
    }
  },
  mounted() {
  },
  methods: {}
}
</script>