<template>
  <div v-html="product.html">
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';


export default {
  props: ['product'],
  data() {
    return {}
  }
  ,
  mounted() {
    // let swiper = new Swiper(this.$el.querySelector('.product-card .swiper'), {
    //   slidesPerView: 1,
    //   loop: true,
    //   nested: true,
    //   pagination: {
    //     el: this.$el.querySelector('.product-card .swiper-pagination'),
    //   },
    //   navigation: {
    //     nextEl: this.$el.querySelector('.product-card .swiper-button-next'),
    //     prevEl: this.$el.querySelector('.product-card .swiper-button-prev'),
    //   },
    // });
  }
  ,
  methods: {}
}
</script>