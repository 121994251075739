<template>
  <div class="nights-options-wrp">
    <template v-for="number in 21">
      <span class="night-item" role="button" :data-nights="number" @click="selectNumber(number)" @mouseover="hoverNumber(number)"
            :class="[
                (selectStarted && number === selectedFrom) || (!selectStarted && number === parseInt(travel.duration.from)) ? 'nights-range-start' : '',
                ((selectStarted && number > selectedFrom) || (!selectStarted && number > parseInt(travel.duration.from))) && ((hoverTo!==null && number < hoverTo) || (!selectStarted && number < parseInt(travel.duration.to))) ? 'nights-range-between' : '',
                (hoverTo!==null && number === hoverTo) || (!selectStarted && number === parseInt(travel.duration.to)) ? 'nights-range-end' : '',
            ]">
        {{ number }}
      </span>
    </template>
  </div>
</template>
<script>
import {travel} from "../reactives/travel";
export default {
  props: [],
  data() {
    return {
      travel,
      selectedFrom:7,
      selectedTo:9,
      hoverTo:null,
      selectStarted:false,
    }
  },
  mounted() {
    this.selectedFrom = this.travel.duration.from;
    this.selectedTo = this.travel.duration.to;
  },
  methods: {
    selectNumber(number) {
      if(this.selectStarted) {
        this.selectedTo = number;
        this.hoverTo = null;
        if(this.selectedTo < this.selectedFrom) {
          this.selectedFrom = this.selectedTo;
        } else {
          this.selectStarted = false;
          this.travel.duration.from =this.selectedFrom;
          this.travel.duration.to =this.selectedTo;
        }
      } else {
        this.selectedFrom = number;
        this.selectedTo = this.selectedFrom;
        this.selectStarted = true;
      }
    },
    hoverNumber(number) {
      if(this.selectStarted) {
        if(number > this.selectedFrom) {
          this.hoverTo = number;
        }
      }
    }
  }
}
</script>