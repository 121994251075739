import {reactive} from 'vue'

export const travel = reactive({
    // departure_airport: [],
    duration: {
        from:7,
        to:9,
    },
    date: {
        from: '',
        to: '',
    },
    adults: 2,
    children: 0,
    childrenAge: [],
    activeDateText: '',
    tab: 'date',
    destination: [],
    hotel: [],
    search: false,
    loaded: false,
    prepareForRequest(categories = [], hotels = []) {
        let params = {
            destination: categories,
            hotel: hotels,
            adults: this.adults,
            children: this.children,
            childrenAge: this.childrenAge,
            duration: this.duration,
            tab: this.tab,
            date: this.date,
        }
        return params;
    }
})