<template>
  <div class="offer-details-placeholder">
    <div class="offer-summary">
      <span class="loader-large-circle"></span>
      <span class="text">{{ t('Tikriname viešbučio užimtumą...') }}</span>
    </div>
    <div class="other-info">
      <div class="placeholder-line">
        <div class="text" style="width: 24%"></div>
      </div>
      <div class="placeholder-line wt-circle">
        <div class="circle"></div>
        <div class="text" style="width: 40%"></div>
      </div>
      <div class="placeholder-line wt-circle">
        <div class="circle"></div>
        <div class="text" style="width: 15%"></div>
      </div>
      <div class="placeholder-line wt-circle">
        <div class="circle"></div>
        <div class="text" style="width: 42%"></div>
      </div>
    </div>
  </div>
</template>