<template>
  <div class="mobile-search-btn">
    <div class="act-toggle-mobile-search" @click="toggleSearchDrawer" role="button">
      <span class="label">{{ t('Kur keliausite?') }}</span>
      <i class="fa-solid fa-magnifying-glass"></i>
      <span class="values">
        <span>{{ destinations.activeText(t('Bet kur')) }}</span> •
        <span v-show="travel.activeDateText.length > 0">{{ travel.activeDateText }}</span>
        <span v-show="travel.activeDateText.length === 0">{{ t('Bet kada') }}</span>•
        <span>{{ passengers_str(travel.adults, travel.children) }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import {travel} from "../reactives/travel";
import {destinations} from "../reactives/destinations";

export default {
  props: [],
  data() {
    return {
      travel,
      destinations,
      isActive: false,
      searchDrawer: false,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    let component = this;
    this.searchDrawer = document.getElementById("mobileSearchDrawer");

    document.querySelector('.search-close-btn').addEventListener('click', (e) => {
      e.preventDefault();
      component.toggleSearchDrawer();
    });
  },
  methods: {
    toggleSearchDrawer: function() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.openSearchDrawer();
      } else {
        this.closeSearchDrawer();
      }
    },

    openSearchDrawer: function() {
      let component = this;

      component.searchDrawer.classList.add("open");
      document.querySelector('body').classList.add('locked');
    },

    closeSearchDrawer: function() {
      let component = this;

      component.searchDrawer.classList.remove("open");
      document.querySelector('body').classList.remove('locked');
    },
  }
}
</script>