<template>
  {block name="description"}
  {if empty($frontend->collection) && empty($frontend->last_category) &&
  empty($frontend->brand) && empty($frontend->category)}
  <div class="html_content">{$h->display_html($frontend->page.content)}</div>
  {/if}
  {if !empty($frontend->collection) && $frontend->collection.description}
  <div class="description">{$h->display_html($frontend->collection.description)}</div>
  {elseif !empty($frontend->last_category) && $frontend->last_category.description}
  <div class="description">{$h->display_html($frontend->last_category.description)}</div>
  {elseif !empty($frontend->category) && $frontend->category.description}
  <div class="description">{$h->display_html($frontend->category.description)}</div>
  {elseif !empty($frontend->brand) && $frontend->brand.description}
  <div class="description">{$h->display_html($frontend->brand.description)}</div>
  {/if}
  {if !empty($frontend->collection) && $frontend->collection.photos_count}
  <div class="banner container-fluid text-center mb-4">
    <img class="img-fluid center-block" src="{$frontend->collection.photo.src}"/>
  </div>
  {elseif !empty($frontend->last_category.photos_count)}
  <div class="banner container-fluid text-center mb-4">
    <img class="img-fluid center-block" src="{$frontend->last_category.photo.src}"/>
  </div>
  {/if}
  {/block}
</template>

<script>
export default {
  props: ['title', 'description'],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>
