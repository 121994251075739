<template>
  <select :placeholder="name" v-model="tempValue" class="form-control border" multiple>
    <template v-for="cat in options">
      <option :value="cat.id"
              :selected="cat.id === modelValue || (Array.isArray(modelValue) && modelValue.includes(cat.id))">
        {{cat.name}}
      </option>
      <option v-for="child in cat.childs" :value="child.id" class="ps-5"
              :selected="child.id === modelValue || (Array.isArray(modelValue) && modelValue.includes(child.id))">
        {{child.name}}
      </option>
    </template>
  </select>
</template>

<script>
export default {
  props: ['options', 'modelValue', 'name'],
  data() {
    return {
      tempValue: []
    }
  },
  watch: {
    tempValue(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  mounted() {
    $(this.$el).selectpicker({});
  },
  updated: function () {
    $(this.$el).selectpicker('destroy');
    $(this.$el).selectpicker({});
    $(this.$el).selectpicker('val', this.modelValue);
  },
  methods: {}
}
</script>
