<template>
  <div class="passengers-options-wrp">

    <div class="passengers-main-options">
      <div class="passengers-select-wrp">
        <label for="number-input" class="form-label">{{t('Suaugę (12+ m.)')}}</label>

        <div class="input-number-group">
          <span @click="addAdults(-1)" :class="{'disabled':parseInt(travel.adults) < 2}"><i class="fa-solid fa-minus"></i></span>
          <input type="number" class="form-control js-counter"
                 @input="handleAdultsInput()"
                 v-model="travel.adults"
                 min="1" :max="adultsMax">
          <span @click="addAdults(1)" :class="{'disabled':parseInt(travel.adults) > (adultsMax - 1)}"><i class="fa-solid fa-plus"></i></span>
        </div>
      </div>

      <div class="passengers-select-wrp">
        <label for="number-input" class="form-label">{{t('Vaikai (0-11 m.)')}}</label>

        <div class="input-number-group">
          <span @click="addChildren(-1)" :class="{'disabled':parseInt(travel.children) < 1}"><i class="fa-solid fa-minus"></i></span>
          <input type="number" class="form-control  js-counter"
                 @input="handleChildsInput()"
                 v-model="travel.children"
                 min="0" :max="childsMax">
          <span @click="addChildren(1)" :class="{'disabled':parseInt(travel.children) > (childsMax - 1)}"><i class="fa-solid fa-plus"></i></span>
        </div>
      </div>
    </div>


    <div class="childs-age-wrp" v-if="travel.children > 0">
      <span class="childs-age-title">{{ t('Vaikų amžius') }}</span>
      <div class="childs-main-options">
        <div class="child-age-select-wrp" v-for="(age,index) in travel.childrenAge">
          <label for="number-input" class="form-label">{{index+1}} {{t('vaikas')}}</label>

          <div class="input-number-group">
            <span @click="addAge(index,-1)" :class="{'disabled':parseInt(travel.childrenAge[index]) < 1}"><i class="fa-solid fa-minus"></i></span>
            <input type="number" class="form-control  js-counter"
                   @input="handleChildAgeInput($event, index)"
                   v-model="travel.childrenAge[index]"
                   min="0" :max="childMaxAge">
            <span @click="addAge(index,1)" :class="{'disabled':parseInt(travel.childrenAge[index]) > (childMaxAge - 1)}"><i class="fa-solid fa-plus"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {travel} from "../reactives/travel";

export default {
  props: [],
  data() {
    return {
      travel,
      childMaxAge: 16,
      childsMax: 9,
      adultsMax: 9,
      tempValue: [],
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    addAdults(inc) {
      if ((parseInt(inc) < 0 && parseInt(this.travel.adults) < 2) ||
          (parseInt(inc) > 0 && parseInt(this.travel.adults) > 9)) {
        return false;
      }
      this.travel.adults = parseInt(this.travel.adults) + parseInt(inc);
    },
    addAge(index, inc) {
      if ((parseInt(inc) < 0 && parseInt(this.travel.childrenAge[index]) < 1) ||
          (parseInt(inc) > 0 && parseInt(this.travel.childrenAge[index]) > (this.childMaxAge - 1))) {
        return false;
      }
      this.travel.childrenAge[index] = parseInt(this.travel.childrenAge[index]) + parseInt(inc);
    },
    handleChildAgeInput($event, index) {
      if (this.travel.childrenAge[index] > this.childMaxAge) {
        this.travel.childrenAge[index] = this.childMaxAge;
      }
    },
    handleAdultsInput() {
      if (this.travel.adults > this.adultsMax) {
        this.travel.adults = this.adultsMax;
      }
    },
    handleChildsInput() {
      if (this.travel.children > this.childsMax) {
        this.travel.children = this.childsMax;
      }

      this.travel.childrenAge = [];
      for (let i = 0; i < this.travel.children; i++) {
        this.travel.childrenAge.push(0);
      }
    },
    addChildren(inc) {
      if ((parseInt(inc) < 0 && parseInt(this.travel.children) < 1) ||
          (parseInt(inc) > 0 && parseInt(this.travel.children) > this.childsMax)) {
        return false;
      }
      this.travel.children = parseInt(this.travel.children) + parseInt(inc);
      if (parseInt(inc) > 0) {
        this.travel.childrenAge.push(0);
      } else {
        this.travel.childrenAge.pop();
      }
    },
  }
}
</script>