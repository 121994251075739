<template>
  <div id="inquiry-dates-block" class="" v-click-outside="closeSearchDropdown">
    <div class="inner-wrp">
      <div class="fields-wrp">
        <div class="dates-wrp inquiry-variable-block" :class="activeVariable == 'dates' ? 'active' : ''">
          <input type="hidden" name="month_from" v-model="travel.month.from" />
          <input type="hidden" name="month_to" v-model="travel.month.to" />
          <input type="hidden" name="tab" v-model="travel.tab" />
          <input type="hidden" name="flex" v-model="travel.flex" />
          <input type="hidden" name="date" v-model="travel.date" />
          
          <div class="summary-wrp" @click="toggleSearchDropdown('dates')">
            <div class="form-group floating-label">
              <label for="field-date">{{t('Kelionės data')}}</label>
              <input type="text" class="form-control" placeholder="{{t('Bet kada')}}" id="field-date"  v-model="travel.activeDateText" autocomplete="off">
            </div>
          </div>

          <div class="custom-dropdown"> 
            <div class="inner-wrp">
              <dates-content resolution="desktop"></dates-content>
            </div>
          </div>
        </div>
      </div>
      <div class="nights-wrp inquiry-variable-block" :class="activeVariable == 'nights' ? 'active' : ''">
        <input type="hidden" name="nights_from" v-model="travel.duration.from"  />
        <input type="hidden" name="nights_to" v-model="travel.duration.to"  />
        <div class="summary-wrp" @click="toggleSearchDropdown('nights')">
          <span class="title">{{ t('Naktų skaičius') }}</span>
          <span class="value">{{ nights_str(travel.duration.from,travel.duration.to) }}</span>
        </div>

        <div class="custom-dropdown">
          <div class="inner-wrp">
            <span class="nights-title">{{ t('Kelionės trukmė') }}</span>
            <nights-content></nights-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatesContent from "../travel/datesContent.vue";
import NightsContent from "../travel/nightsContent.vue";
import {travel} from "../reactives/travel";

export default {
  components: {NightsContent, DatesContent},
  props: ['standalone', 'url', 'durations'],
  data() {
    return {
      destinationsDrawer: false,
      activeVariable: false,
      desktopSearchForm: false,
      travel,
      // mainMenu: window.main_menu,
    }
  },
  mounted() {
    let component = this;

    component.desktopSearchForm = document.getElementById("desktopSearchForm");
    // this.destinationsDrawer = document.querySelector(".destinations-drawer");
    // document.querySelectorAll('.act-open-mob-destination, .destination-wrp.search-variable-block .summary-wrp').forEach(function (el) {
    //   el.addEventListener('click', (e) => {
    //     component.openDestinationsDrawer();
    //   });
    // })


    // document.querySelector('.act-close-destination-drawer').addEventListener('click', (e) => {
    //   this.closeDestinationsDrawer();
    // })
  },
  methods: {
    closeSearchDropdown() {
      this.activeVariable = false;
    },
    toggleSearchDropdown(type) {
      if (this.activeVariable == type) {
        this.activeVariable = false;
      } else {
        this.activeVariable = type;
      }
    },
    submitSearch() {
      if (parseInt(this.standalone) === 1) {
        window.location.replace(this.url+ '?' + decodeURIComponent($.param({
          travel: this.travel.prepareForRequest(Object.keys(this.destinations.activeCategories),Object.keys(this.destinations.activeHotels))
        })))
      }  else {
        this.travel.search = true;
      }
    }
  }
}
</script>