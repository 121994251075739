<template>
  <div class="help-block">
    <a href="javascript:;" class="inner-wrp" data-bs-toggle="modal" data-bs-target="#helpDrawer">
      <div class="photo-wrp">
        <div class="person-wrp">
          <span v-html="help_image_html"></span>
<!--          <img class="person-photo img-fluid" :src="help_image" >-->
        </div>
      </div>
      <div class="text-wrp">
        <span class="title">{{ t('Reikia pagalbos?') }}</span>
        <span class="slogan">{{ t('Esame čia, kad padėtume') }}</span>
      </div>
      <div class="actions-wrp">
        <div class="icon-wrp">
          <i class="fa-solid fa-messages"></i>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ['image_url'],
  data() {
    return {
      help_image: window.help_image,
      help_image_html: window.help_image_html
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>