<template>
  <div class="offer-card">
    <div class="tags-wrp">
      <span class="tag tag-cheapest" v-show="offer.price<=cheapestPrice">{{t('Pigiausias')}}</span>
<!--      <span class="tag tag-recommend">{t('Rekomenduoja')}</span>-->
    </div>
    <div class="price-wrp">
      <div class="person-price-wrp">
        <span class="price">{{ offer.person_price_formatted }}</span>
        <span class="old-price" v-if="Math.ceil(offer.person_price)<Math.ceil(offer.regular_price)">{{ offer.regular_price_formatted }}</span>
        <span class="price-prefix">{{t('/asm.')}}</span>
      </div>
      <div class="total-price-wrp">
        <span class="total-price">{{ offer.price_formatted }}</span>
        <span class="price-prefix">{{t('visiems')}}</span>
      </div>
    </div>
    <div class="details-wrp">
      <ul class="round-checkmark-list">
        <li>{{offer.duration}} {{ str_by_number(offer.duration,[t('naktis'),t('naktys'),t('naktų')]) }}</li>
        <li>{{offer.pansion.name}}</li>
        <li>{{offer.hotel_room_name}}</li>
      </ul>

      <a @click="selectOffer" class="btn-more" role="button" data-bs-toggle="modal" data-bs-target="#offerDetailsModal">
        <i class="fa-regular fa-plus"></i>
        <span>{{t('Daugiau')}}</span>
      </a>
    </div>
    <div class="travel-dates-wrp">
      <span class="dates">{{ offer.departure_date }} - {{ offer.return_date }}</span>
      <div class="flights-info">
        <div class="info-collapsed">
          <a @click="loadFlights" class="btn-flights act-toggle-flight-info" role="button">{{t('Skrydžių laikai')}}</a>
          <i class="fa-regular fa-circle-info"
             data-bs-toggle="tooltip"
             data-bs-placement="bottom"
             :title="t('-offer_flight_tooptip_text-')"></i>
        </div>
        <div class="info-expanded" v-if="loading">
          <span class="loader-small-circle"></span>
        </div>
        <div class="info-expanded" v-if="!loading && flightData !== null">
          <div class="flight-stage">
            <span class="flight-time">{{ flightData.forward_time }}</span>
            <span class="flight-location">{{ flightData.forward_airports }}</span>
          </div>
          <div class="flight-stage">
            <span class="flight-time">{{ flightData.return_time }}</span>
            <span class="flight-location">{{ flightData.return_airports }}</span>
          </div>
        </div>
        <div class="info-expanded" v-if="!loading && expired">
          EXPIRED
        </div>
      </div>
    </div>
    <div class="actions-wrp">
      <a @click="selectOffer" class="btn btn-primary w-100" role="button" data-bs-toggle="modal" data-bs-target="#offerDetailsModal">
        {{t('Užsakyti')}} <i class="fa-solid fa-chevron-right ms-3 mb-n1"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['offer','cheapestPrice','productId'],
  data() {
    return {
      loading:false,
      pid:false,
      priceInfo: false,
      flightData: null,
      expired: false,
    }
  },
  watch: {

  },
  mounted() {
  },
  updated: function () {

  },
  methods: {
    selectOffer() {
      this.$emit('offer:selected',this.offer);
    },
    loadFlights() {
      let component = this;
      component.loading = true;
      $.ajax({
        type: 'GET',
        url: '?display=content_types/products/travel_option_details.tpl',
        data: {
          operator: this.offer.operator,
          departure: this.offer.departure_date,
          duration: this.offer.duration,
          adults: this.offer.adults,
          children: this.offer.children,
          children_ages: this.offer.children_ages,
          departure_airport: this.offer.departure_airport,
          product: this.productId,
          pansion: this.offer.pansion.id,
          room: this.offer.hotel_room_name,
          price: this.offer.price,
          price_info: this.priceInfo?1:0,
          pid: this.pid===false?0:this.pid
        },
        success: function (data) {
          if(typeof data.background !== 'undefined') {
            if(component.pid !== false) {
              if(parseInt(component.pid) !== parseInt(data.pid)) {
                component.priceInfo = true;
              }
            }
            component.pid = data.pid;
            component.timer = setTimeout(function () {
              component.loadFlights();
            }, 1000);
          } else {
            component.pid = false;
            component.priceInfo = false;
            component.loading = false;
            if(typeof data.status === 'undefined') {
              component.flightData = data.flight_data;
            } else {
              component.expired = true;
            }
          }
        }
      })
    }
  }
}
</script>