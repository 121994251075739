<template>
  <div class="content-grid">
    <div class="content-small">
      <div id="desktopSearchForm" class="" v-click-outside="closeSearchDropdown">
        <div class="inner-wrp">
          <div class="fields-wrp">
            <div class="destination-wrp search-variable-block" :class="activeVariable == 'destination' ? 'active' : ''">
              <div class="summary-wrp" @click="toggleSearchDropdown('destination')">
                <span class="title">{{ t('Kur keliausite?') }}</span>
                <span class="value">{{ destinations.activeText(t('Bet kur')) }}</span>
              </div>
              <div class="custom-dropdown">
                <div class="inner-wrp">
                  <destinations-content ref="destinationsContent" resolution="desktop"></destinations-content>
                </div>
              </div>
            </div>

            <div class="dates-wrp search-variable-block" :class="activeVariable == 'dates' ? 'active' : ''">
              <div class="summary-wrp" @click="toggleSearchDropdown('dates')">
                <span class="title">{{ t('Data') }}</span>
                <span class="value" v-show="travel.activeDateText.length > 0">{{ travel.activeDateText }}</span>
                <span class="value" v-show="travel.activeDateText.length === 0">{{ t('Bet kada') }}</span>
              </div>

              <div class="custom-dropdown">
                <div class="inner-wrp">
                  <dates-content resolution="desktop"></dates-content>
                </div>
              </div>
            </div>

            <div class="nights-wrp search-variable-block" :class="activeVariable == 'nights' ? 'active' : ''">
              <div class="summary-wrp" @click="toggleSearchDropdown('nights')">
                <span class="title">{{ t('Naktys') }}</span>
                <span class="value">{{ nights_str(travel.duration.from,travel.duration.to) }}</span>
              </div>

              <div class="custom-dropdown">
                <div class="inner-wrp">
                  <span class="nights-title">{{ t('Kelionės trukmė') }}</span>
                  <nights-content></nights-content>
                </div>
              </div>
            </div>

            <div class="passengers-wrp search-variable-block" :class="activeVariable == 'passengers' ? 'active' : ''">
              <div class="summary-wrp" @click="toggleSearchDropdown('passengers')">
                <span class="title">{{ t('Keleiviai') }}</span>
                <span class="value">{{ passengers_str(travel.adults, travel.children) }}</span>
              </div>

              <div class="custom-dropdown">
                <div class="inner-wrp">
                  <passengers-content></passengers-content>
                </div>
              </div>
            </div>
          </div>
          <div class="actions-wrp">
            <button class="btn btn-primary" @click="submitSearch"><i class="fa-solid fa-magnifying-glass me-2 mb-n1"></i>{{ t('Ieškoti') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {travel} from "../reactives/travel";
import {destinations} from "../reactives/destinations";
import DestinationsContent from "./destinationsContent.vue";
import DatesContent from "./datesContent.vue";
import NightsContent from "./nightsContent.vue";
import PassengersContent from "./passengersContent.vue";

export default {
  components: {PassengersContent, NightsContent, DatesContent, DestinationsContent},
  props: ['standalone', 'url', 'durations'],
  data() {
    return {
      destinationsDrawer: false,
      activeVariable: false,
      desktopSearchForm: false,
      travel,
      destinations,
      // mainMenu: window.main_menu,
    }
  },
  mounted() {
    let component = this;

    component.desktopSearchForm = document.getElementById("desktopSearchForm");
    // this.destinationsDrawer = document.querySelector(".destinations-drawer");
    // document.querySelectorAll('.act-open-mob-destination, .destination-wrp.search-variable-block .summary-wrp').forEach(function (el) {
    //   el.addEventListener('click', (e) => {
    //     component.openDestinationsDrawer();
    //   });
    // })


    // document.querySelector('.act-close-destination-drawer').addEventListener('click', (e) => {
    //   this.closeDestinationsDrawer();
    // })
  },
  methods: {
    closeSearchDropdown() {
      this.activeVariable = false;
    },
    toggleSearchDropdown(type) {
      if (this.activeVariable == type) {
        this.activeVariable = false;
      } else {
        this.activeVariable = type;
      }
    },
    submitSearch() {
      if (parseInt(this.standalone) === 1) {
        window.location.replace(this.url+ '?' + decodeURIComponent($.param({
          travel: this.travel.prepareForRequest(Object.keys(this.destinations.activeCategories),Object.keys(this.destinations.activeHotels))
        })))
      }  else {
        this.travel.search = true;
      }
    }
  }
}
</script>