<template>
  <div id="mobileSearchDrawer" aria-modal="true">
    <div class="inner-wrp" tabindex="-1" role="presentation">
      <div class="header-wrp">
        <button class="search-close-btn" :aria:lavel="t('Uždaryti')">
          <i class="fa-regular fa-xmark"></i>
        </button>
      </div>
      <div class="content-wrp">
        <div class="search-variables-wrp">
          <div class="destination-wrp search-variable-block"
               :class="[activeVariable == 'destination' ? 'active' : '']"
               data-step="1">
            <div class="summary-wrp">
              <span class="title">{{ t('Kur?') }}</span>
              <span class="value">{{ destinations.activeText(t('Bet kur')) }}</span>
            </div>
            <div class="no-values-wrp">
              <span class="title">{{ t('Kur Keliausite?') }}</span>
              <div class="fake-field act-open-mob-destination" role="button">
                <i class="fa-solid fa-magnifying-glass"></i>
                <span>{{ t('Ieškoti šalies ar miesto') }}</span>
              </div>
            </div>

            <div class="destinations-drawer">
              <destinations-content ref="destinationsContent" resolution="mobile"></destinations-content>
              <div class="actions-wrp">
                <button class="btn btn-primary" v-on:click.stop="this.closeDestinationsDrawer(); this.changeActiveVariable('dates');">{{t('Toliau')}}</button>
              </div>
            </div>
          </div>

          <div class="dates-wrp search-variable-block"
               :class="[activeVariable == 'dates' ? 'active' : '']"
               data-step="2"
               @click="activeVariable != 'dates' ? activeVariable = 'dates' : null">
            <div class="summary-wrp">
              <span class="title">{{ t('Kada?') }}</span>
              <span class="value" v-show="travel.activeDateText.length > 0">{{ travel.activeDateText }}</span>
              <span class="value" v-show="travel.activeDateText.length === 0">{{ t('Bet kada') }}</span>
            </div>

            <div class="variable-content-wrp">
              <div class="variable-inner-wrp">
                <dates-content resolution="mobile"></dates-content>
                <div class="actions-wrp">
                  <button class="btn btn-primary" v-on:click.stop="this.changeActiveVariable('nights');">
                    {{t('Toliau')}}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="nights-wrp search-variable-block"
               :class="[activeVariable == 'nights' ? 'active' : '']"
               data-step="3"
               @click="activeVariable != 'nights' ? activeVariable = 'nights' : null">
            <div class="summary-wrp">
              <span class="title">{{ t('Kiek naktų?') }}</span>
              <span class="value">{{ nights_str(travel.duration.from,travel.duration.to) }}</span>
            </div>

            <div class="variable-content-wrp">
              <div class="variable-inner-wrp">
                <nights-content></nights-content>
                <div class="actions-wrp">
                  <button class="btn btn-primary" v-on:click.stop="this.changeActiveVariable('passengers');">
                    {{t('Toliau')}}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="passengers-wrp search-variable-block"
               :class="[activeVariable == 'passengers' ? 'active' : '']"
               data-step="4"
               @click="activeVariable != 'passengers' ? activeVariable = 'passengers' : null">
            <div class="summary-wrp">
              <span class="title">{{ t('Kas?') }}</span>
              <span class="value">{{ passengers_str(travel.adults, travel.children) }}</span>
            </div>

            <div class="variable-content-wrp">
              <div class="variable-inner-wrp">
                <passengers-content></passengers-content>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-wrp" v-show="activeVariable == 'passengers'">
        <button class="btn btn-link">{{ t('Išvalyti viską') }}</button>
        <button class="btn btn-primary" @click="submitSearch"><i class="fa-solid fa-magnifying-glass me-2 mb-n1"></i>{{ t('Ieškoti') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {travel} from "../reactives/travel";
import {destinations} from "../reactives/destinations";
import DestinationsContent from "./destinationsContent.vue";
import DatesContent from "./datesContent.vue";
import NightsContent from "./nightsContent.vue";
import PassengersContent from "./passengersContent.vue";

export default {
  components: {PassengersContent, NightsContent, DatesContent, DestinationsContent},
  props: ['categories', 'standalone', 'url', 'durations'],
  data() {
    return {
      destinationsDrawer: false,
      activeVariable: 'categories',
      travel,
      destinations,
    }
  },
  mounted() {
    let component = this;

    this.destinationsDrawer = document.querySelector(".destinations-drawer");
    document.querySelectorAll('.act-open-mob-destination, .destination-wrp.search-variable-block .summary-wrp').forEach(function (el) {
      el.addEventListener('click', (e) => {
        component.openDestinationsDrawer();
      });
    })


    document.querySelector('.act-close-destination-drawer').addEventListener('click', (e) => {
      this.closeDestinationsDrawer();
    })
  },
  methods: {
    openDestinationsDrawer() {
      this.destinationsDrawer.classList.add('open');
    },
    closeDestinationsDrawer() {
      this.destinationsDrawer.classList.remove('open');
    },
    changeActiveVariable(type) {
      this.activeVariable = type;

      // document.querySelector('.search-variables-wrp .search-variable-block.'+ type +'-wrp').scrollIntoView(true);
    },

    submitSearch() {
      if (parseInt(this.standalone) === 1) {
        window.location.replace(this.url+ '?' + decodeURIComponent($.param({
          travel: this.travel.prepareForRequest(Object.keys(this.destinations.activeCategories),Object.keys(this.destinations.activeHotels))
        })))
      }  else {
        this.travel.search = true;
        document.getElementById("mobileSearchDrawer").classList.remove("open");
      }
    }
  }
}
</script>