<template>
  <div class="group-item" :class="{'open':showChildren}" v-show="isVisible">
    <div class="item-main" :class="[!isHotel && destination.childs ? 'has-childs' : '']">
        <span class="item-name"
              @click="destination.toggleActive"
              :data-id="destination.id"
              :data-parent="1"
              :class="[
                  destination.active ? 'active' : '',
                  destination.partial ? 'semi-active' : ''
              ]">
          {{ destination.name}}<span v-if="!isHotel && destination.childs && search.length === 0" class="counter"> ({{destination.childs.length}})</span>
          <span v-if="isHotel" class="country">{{destination.city}}, {{destination.country}}</span>
        </span>
        <span class="childs-indicator" @click="showChildren=!showChildren" v-if="!isHotel && destination.childs">
          <i class="fa-regular fa-chevron-down"></i>
        </span>
    </div>
    <div class="item-childs-wrp" v-if="!isHotel && destination.childs">
      <div class="childs-inner-wrp">
        <div class="item-child" v-for="(child,childKey) in destination.childs" v-show="child.isVisible">
          <span class="item-name"
                @click="destination.toggleChild(childKey)"
                :data-id="child.id"
                :data-parent="0"
                :class="[destination.active || child.active ? 'active' : '']">
            {{ child.name}}
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {travel} from "../reactives/travel";
import DatesContent from "./datesContent.vue";

export default {
  components: {DatesContent},
  props: ['destination','search', 'isHotel'],
  emits: [],
  data() {
    return {
      travel,
      showChildren: false,
      isVisible: true,
    }
  },
  computed: {
  },
  watch: {
    search(newVal,OldVal) {
      if(newVal.length > 0 ) {
        if(this.destination.searchParams.includes(newVal.toLowerCase())) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
        if(typeof this.destination.childs !== 'undefined' && this.destination.childs.length>0) {
          this.destination.childs.forEach(function(child) {
            if(child.searchParams.includes(newVal.toLowerCase())) {
              child.isVisible = true;
            } else {
              child.isVisible = false;
            }
          });
        }
      } else {
        this.isVisible = true;
        if(typeof this.destination.childs !== 'undefined' && this.destination.childs.length>0) {
          this.destination.childs.forEach(function (child) {
            child.isVisible = true;
          });
        }
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
