<template>
  <div class="dates-options-wrp">
    <div class="date-type-tabs">
      <span @click="changeTab('date')" :class="{active:travel.tab==='date'}">{{ t('Išvykimo diena') }}</span>
      <span @click="changeTab('month')" :class="{active:travel.tab==='month'}">{{ t('Lanksčios') }}</span>
    </div>
    <div class="select-by-types-wrp">
      <div class="select-by-date-wrp" v-show="travel.tab==='date'">
        <div class="calendar-wrp">
          <VueDatePicker v-if="resolution == 'mobile'" v-model="date"
                         :enable-time-picker="false"
                         inline
                         month-name-format="long"
                         vertical
                         no-swipe
                         auto-apply
                         :config="{ noSwipe: true }"
                         :multi-calendars="{ count: 12 }"
                         :month-change-on-scroll="false"
                         :month-change-on-drag="false"
                         :min-date="new Date()"
                         locale="lt"
                         range @range-start="selectSingleDate"
          >
            <template
                #month-year="{
                    month,
                    year,
                    months,
            }">
              <div class="custom-month-year-component">
                <span>{{ year }}</span>
                <span>{{ months[month].text }}</span>
              </div>
            </template>
          </VueDatePicker>
          <VueDatePicker v-if="resolution == 'desktop'" v-model="date"
                         :enable-time-picker="false"
                         inline
                         month-name-format="long"
                         auto-apply
                         :multi-calendars="{ count: 2 }"
                         :month-change-on-scroll="false"
                         :month-change-on-drag="false"
                         :min-date="new Date()"
                         locale="lt"
                         range @range-start="selectSingleDate"
          >
            <template
                #month-year="{
                    month,
                    year,
                    months,
                    handleMonthYearChange
            }">
              <div class="custom-month-year-component">
                <span
                    class="months-change-icon month-prev"
                    @click="handleMonthYearChange(false)">
                  <i class="fa-solid fa-chevron-left"></i>
                </span>

                <span>{{ year }}</span>
                <span>{{ months[month].text }}</span>

                <span
                    class="months-change-icon month-next"
                    @click="handleMonthYearChange(true)">
                  <i class="fa-solid fa-chevron-right"></i>
                </span>
              </div>

            </template>
          </VueDatePicker>
        </div>
<!--        <div class="flex-days-wrp">-->
<!--          <div class="custom-swiper-wrp">-->
<!--            <template v-for="(flex, index) in flex_options">-->
<!--              <span class="custom-swiper-item flex-days-item"-->
<!--                    @click="changeFlex(index)"-->
<!--                    :class="{'active':travel.flex===index}" role="button">-->
<!--                {{ flex }}-->
<!--              </span>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="select-by-month-wrp" v-show="travel.tab==='month'">
        <div class="year-groups-wrp">
          <div class="year-group" v-for="(months,year) in years">
            <span class="year-title">{{ year }}</span>
            <div class="months-wrp">
              <div class="month-item" v-for="(month,index) in months"
                   @click="selectMonth(year,index)" @mouseover="hoverMonth(year,index)"
                   :class="[
                (selectStarted && (year*100+parseInt(index)) === selectedFrom) || (!selectStarted && (year*100+parseInt(index)) === parseInt(monthFrom)) ? 'month-range-start' : '',
                ((selectStarted && (year*100+parseInt(index)) > selectedFrom) || (!selectStarted && (year*100+parseInt(index)) > parseInt(monthFrom))) && ((hoverTo!==null && (year*100+parseInt(index)) < hoverTo) || (!selectStarted && (year*100+parseInt(index)) < parseInt(monthTo))) ? 'month-range-between' : '',
                (hoverTo!==null && (year*100+parseInt(index)) === hoverTo) || (!selectStarted && (year*100+parseInt(index)) === parseInt(monthTo)) ? 'month-range-end' : '',
                    ]">
                {{ month }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {travel} from "../reactives/travel";
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment';

export default {
  components: {VueDatePicker},
  props: ['durations', 'resolution'],
  emits: [],
  data() {
    return {
      travel,
      tab: 'date',
      date: [],
      years: {},
      // flex_options: {
      //   "0": this.t('Tiksli data'),
      //   "1": this.t('± 1 d.'),
      //   "2": this.t('± 2 d.'),
      //   "3": this.t('± 3 d.'),
      //   "7": this.t('± 7 d.'),
      // },
      monthFrom:null,
      monthTo:null,
      selectedFrom:null,
      selectedTo:null,
      hoverTo:null,
      selectStarted:false,
      skipWatch: false,
      first: true,
    }
  },
  computed: {

  },
  watch: {
    date(newVal,oldVal) {
      if(this.skipWatch) {
        this.skipWatch = false;
      } else {
        if (oldVal !== newVal) {
          if (moment(newVal[0]).isValid()) {
            this.travel.date.from = moment(newVal[0]).format('YYYY-MM-DD');
          }
          if (moment(newVal[1]).isValid()) {
            this.travel.date.to = moment(newVal[1]).format('YYYY-MM-DD');
          }
          this.travel.activeDateText = this.travel.date.from;
          if (this.travel.date.from !== this.travel.date.to) {
            this.travel.activeDateText += ' - ' + this.travel.date.to;
          }
        }
      }
    },
    'travel.date.from': function(newVal,oldVal) {
        if (oldVal !== newVal && this.first) {
          this.first = false;
          if(this.travel.tab === 'month') {
            this.monthFrom = moment(this.travel.date.from).format('YYYYMM');
            this.monthTo = moment(this.travel.date.to).format('YYYYMM');
          } else {
            this.skipWatch = true;
            this.date = [moment(this.travel.date.from).toDate(), moment(this.travel.date.to).toDate()];
          }
        }
    }
  },
  mounted() {
    moment.locale('lt');
    for (let i = 0; i <= 12; i++) {
      let newMonth = moment().add(i, 'months');
      if (typeof (this.years[newMonth.format('YYYY')]) === 'undefined') {
        this.years[newMonth.format('YYYY')] = {};
      }
      this.years[newMonth.format('YYYY')][[newMonth.format('M')]] = newMonth.format('MMMM');
    }
  },
  methods: {
    selectMonth(year, month) {
      let number = year * 100 + parseInt(month);
      if(month < 10) {
        month = '0' + month;
      }
      if(this.selectStarted) {
        this.selectedTo = number;
        this.hoverTo = null;
        if(this.selectedTo < this.selectedFrom) {
          this.selectedFrom = this.selectedTo;
          this.monthFrom = this.selectedFrom;
          this.travel.date.from = moment(year+'-'+month+'-01').format('YYYY-MM-DD');
          this.travel.date.to = moment(year+'-'+month+'-01').endOf('month').format('YYYY-MM-DD');
          this.travel.activeDateText =moment(year+'-'+month+'-01').format('YYYY MMMM');
        } else {
          this.selectStarted = false;
          let startYear = Math.floor(this.selectedFrom/100);
          let startMonth = Math.floor(this.selectedFrom%100);
          if(startMonth < 10) {
            startMonth = '0' + startMonth;
          }
          this.travel.date.from = moment(startYear+'-'+startMonth+'-01').format('YYYY-MM-DD');
          this.travel.date.to = moment(year+'-'+month+'-01').endOf('month').format('YYYY-MM-DD');
          this.monthFrom = this.selectedFrom;
          this.monthTo = this.selectedTo;
          if(this.selectedFrom === this.selectedTo) {
            this.travel.activeDateText =moment(year+'-'+month+'-01').format('YYYY MMMM');
          } else {
            this.travel.activeDateText = moment(startYear+'-'+startMonth+'-01').format('YYYY MMMM') +' - '+moment(year+'-'+month+'-01').format('YYYY MMMM');
          }
        }
      } else {
        this.monthFrom = this.selectedFrom;
        this.monthTo = this.selectedTo;
        this.travel.date.from = moment(year+'-'+month+'-01').format('YYYY-MM-DD');
        this.travel.date.to = moment(year+'-'+month+'-01').endOf('month').format('YYYY-MM-DD');
        this.travel.activeDateText =moment(year+'-'+month+'-01').format('YYYY MMMM');
        this.selectedFrom = number;
        this.selectedTo = this.selectedFrom;
        this.selectStarted = true;
      }
    },
    hoverMonth(year, month) {
      let number = year * 100 + parseInt(month);
      if(this.selectStarted) {
        if(number > this.selectedFrom) {
          this.hoverTo = number;
        }
      }
    },
    changeTab(tab) {
      this.travel.tab = tab;
    },
    changeFlex(flex) {
      this.travel.flex = flex;
    },
    changeDuration(duration) {
      this.travel.duration = duration;
    },
    selectSingleDate(date) {
      this.first = false;
      this.travel.date.from = moment(date).format('YYYY-MM-DD');
      this.travel.date.to = moment(date).format('YYYY-MM-DD');
      this.travel.activeDateText = moment(date).format('YYYY-MM-DD');
    }
  }
}

</script>