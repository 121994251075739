import {reactive} from 'vue'

export const destinations = reactive({
    categories: [],
    hotels: [],
    activeCategories: {},
    activeHotels: {},
    activeText(emptyText) {
        let names = [];
        let component = this;
        Object.keys(this.activeCategories).forEach(function(key) {
            names.push(component.activeCategories[key].name);
        });
        Object.keys(this.activeHotels).forEach(function(key) {
            names.push(component.activeHotels[key].name);
        });
        if (names.length > 0) {
            return names.join(', ');
        } else {
            return emptyText
        }
    },
    pushHotel(hotel,selectedHotels) {
        hotel.active = false;
        hotel.searchParams =  hotel.name.toLowerCase()+' '+hotel.country.toLowerCase()+' '+hotel.city.toLowerCase();
        let component = this;
        if(selectedHotels.includes(hotel.id)) {
            component.activeHotels[hotel.id] = {'name':hotel.name}
            hotel.active = true;
        }
        hotel.toggleActive = function() {
            this.active = !this.active;
            if(this.active) {
                component.activeHotels[this.id] = {'name':this.name}
            } else {
                delete component.activeHotels[this.id];
            }
        }

        hotel.name = hotel.name.replace('&amp;', '&');
        
        this.hotels.push(hotel);
    },
    pushCategory(category,selectedCategory) {
        let component = this;
        category.partial = false;
        category.active = false;
        category.searchParams =  category.name.toLowerCase();
        category.childs.forEach(function(child) {
            child.active = false;
            child.searchParams =  category.name.toLowerCase()+' '+child.name.toLowerCase();
            child.isVisible = true;
            category.searchParams += ' '+child.name.toLowerCase();
            if(selectedCategory.includes(child.id)) {
                component.activeCategories[child.id] = {'name':child.name}
                category.partial = true;
            }
        });
        if(selectedCategory.includes(category.id)) {
            component.activeCategories[category.id] = {'name':category.name}
            category.active = true;
        }
        category.toggleActive = function() {
            if(this.partial) {
                this.childs.forEach(function(child) {
                    delete component.activeCategories[child.id];
                    child.active = false;
                });
                this.partial = false;
                component.activeCategories[this.id] = {'name':this.name}
                this.active = true;
            } else {
                this.active = !this.active;
                if(this.active) {
                    component.activeCategories[this.id] = {'name':this.name}
                } else {
                    delete component.activeCategories[this.id];
                }
            }
        };
        category.toggleChild = function(key) {
            if(this.active) {
                this.active = false;
                delete component.activeCategories[this.id];
                this.partial = true;
                this.childs.forEach(function(child, childKey) {
                    if(childKey !== key) {
                        child.active = true;
                        component.activeCategories[child.id] = {'name':child.name}
                    }
                });
            } else {
                if(this.partial) {
                    this.childs[key].active = !this.childs[key].active;
                    if(this.childs[key].active) {
                        component.activeCategories[this.childs[key].id] = {'name':this.childs[key].name}
                    } else {
                        delete component.activeCategories[this.childs[key].id];
                    }
                    let c = 0;
                    this.childs.forEach(function(child) {
                        if(child.active) {
                            c++;
                        }
                    });
                    if(c === 0) {
                        this.partial = false;
                    } else {
                        if(c === this.childs.length) {
                            this.partial = false;
                            component.activeCategories[this.id] = {'name':this.name}
                            this.active = true;
                            this.childs.forEach(function(child) {
                                component.activeCategories[child.id] = {'name':child.name}
                                child.active = false;
                            });
                        }
                    }
                } else {
                    if(this.childs.length === 1) {
                        this.active = true;
                        component.activeCategories[this.id] = {'name':this.name}
                    } else {
                        this.partial = true;
                        this.childs[key].active = true;
                        component.activeCategories[this.childs[key].id] = {'name':this.childs[key].name}
                    }
                }
            }
        };
        this.categories.push(category);
    }
});