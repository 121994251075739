<template>
  <div class="input-group"><label for="passengers" class="input-group-text d-flex justify-content-center"
                                  style="min-width:49px;"><i class="fa fa-person"></i></label>
    <input type="button" id="passengers" class=" form-control text-start ps-4 rounded-end" data-bs-toggle="dropdown"
           data-bs-auto-close="outside"
           aria-expanded="false" :value="passengers_str(travel.adults, travel.children)" readonly/>
    <div class="dropdown-menu" style="min-width:350px;">
      <div class="d-flex g-3 align-items-center px-3 py-2">
        <div class="form-group me-3">
          <label for="number-input" class="form-label">{{t('Suaugusieji')}}</label>
          <span @click="addAdults(-1)" :class="{'disabled':parseInt(travel.adults)<2}">-</span>
          <input type="number" class="form-control js-counter" v-model="travel.adults" min="1" max="9">
          <span @click="addAdults(1)" :class="{'disabled':parseInt(travel.adults)>9}">+</span>
        </div>
        <div class="form-group me-3">
          <label for="number-input" class="form-label">{{t('Vaikai')}}</label>
          <span @click="addChildren(-1)" :class="{'disabled':parseInt(travel.children)<1}">-</span>
          <input type="number" class="form-control  js-counter" v-model="travel.children" min="0" max="9">
          <span @click="addChildren(1)" :class="{'disabled':parseInt(travel.children)>9}">+</span>
        </div>
        <div class="form-group" v-for="(age,index) in travel.childrenAge">
          <label for="number-input" class="form-label">{{t('Amžius')}} #{{index+1}}</label>
          <span @click="addAge(index,-1)" :class="{'disabled':parseInt(travel.childrenAge[index])<1}">-</span>
          <input type="number" class="form-control  js-counter" v-model="travel.childrenAge[index]" min="0" max="18">
          <span @click="addAge(index,1)" :class="{'disabled':parseInt(travel.childrenAge[index])>18}">+</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {travel} from "../reactives/travel";

export default {
  props: [],
  data() {
    return {
      travel,
      tempValue: [],
    }
  },
  computed: {
  },
  watch: {
    'travel.children': function (newVal, oldVal) {
      if (newVal > oldVal) {
        for (let i = 0; i < newVal - oldVal; i++) {
          this.travel.childrenAge.push(0);
        }
      } else {
        for (let i = 0; i < oldVal - newVal; i++) {
          this.travel.childrenAge.pop();
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    addAdults(inc) {
      if ((parseInt(inc) < 0 && parseInt(this.travel.adults) < 2) ||
          (parseInt(inc) > 0 && parseInt(this.travel.adults) > 9)) {
        return false;
      }
      this.travel.adults = parseInt(this.travel.adults) + parseInt(inc);
    },
    addAge(index, inc) {
      if ((parseInt(inc) < 0 && parseInt(this.travel.childrenAge[index]) < 1) ||
          (parseInt(inc) > 0 && parseInt(this.travel.childrenAge[index]) > 17)) {
        return false;
      }
      this.travel.childrenAge[index] = parseInt(this.travel.childrenAge[index]) + parseInt(inc);
    },
    addChildren(inc) {
      if ((parseInt(inc) < 0 && parseInt(this.travel.children) < 1) ||
          (parseInt(inc) > 0 && parseInt(this.travel.children) > 9)) {
        return false;
      }
      this.travel.children = parseInt(this.travel.children) + parseInt(inc);
    },
  }
}
</script>
