<template>
  <button class="burger-btn" role="button" aria-label="Meniu" aria-expanded="false" aria-haspopup="dialog" @click="toggleMainMenu">
    <div class="hamburger-lines">
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </div>
  </button>
</template>

<script>

export default {
  props: {},
  data() {
    return {
      isActive: false,
      backdrop: false,
      menu: false,
    }
  },
  mounted() {
    let component = this;
    this.backdrop = document.querySelector('.menu-mob-backdrop');
    this.menu = document.getElementById('mainMenuMob');

    component.menuGroupsToggle();
    document.querySelector('.menu-close-btn').addEventListener('click', function(e) {
      e.preventDefault();

      component.toggleMainMenu();
    });
    this.backdrop.addEventListener('click', function(e) {
      component.toggleMainMenu();
    });

    window.addEventListener('resize', function(e) {
      let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw >= 992 && component.isActive == true) {

        component.toggleMainMenu();
      }
    });
  },
  methods: {
    toggleMainMenu: function() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },

    openMenu: function() {
      let component = this;

      component.menu.classList.add('open');
      component.backdrop.classList.add('active');
      document.querySelector('body').classList.add('locked');
    },

    closeMenu: function() {
      let component = this;
      let return_btn = component.menu.querySelector('.header-wrp .action-wrp .btn-return');

      delete return_btn.dataset.id;
      return_btn.classList.add('d-none');

      component.menu.classList.remove('open');
      component.menu.querySelectorAll('.inner-menu-group-wrp').forEach(submenu => submenu.classList.remove('open'));
      component.backdrop.classList.remove('active');
      document.querySelector('body').classList.remove('locked');
    },

    menuGroupsToggle: function() {
      let component = this;

      component.menu.querySelectorAll('.act-open-submenu').forEach(submenu =>
          submenu.addEventListener('click', function(e) {
            e.preventDefault();

            let submenu_id = this.dataset.id;
            let return_btn = component.menu.querySelector('.header-wrp .action-wrp .btn-return');
            return_btn.dataset.id = submenu_id;

            component.menu.querySelector('.inner-menu-group-wrp[data-submenu-id="' + submenu_id + '"]').classList.add('open');
            return_btn.classList.remove('d-none');
          })
      );

      component.menu.querySelectorAll('.act-close-submenu').forEach(submenu =>
          submenu.addEventListener('click', function(e) {
            e.preventDefault();

            let return_btn = component.menu.querySelector('.header-wrp .action-wrp .btn-return');
            let submenu_id = this.dataset.id;
            let submenu = document.querySelector('.inner-menu-group-wrp[data-submenu-id="' + submenu_id + '"]');

            let parent = submenu.dataset.parent;

            if (parent > 0) {
              return_btn.dataset.id = parent;
            } else {
              delete return_btn.dataset.id;
              return_btn.classList.add('d-none');
            }
            submenu.classList.remove('open');
            setTimeout(function() {
              submenu.scrollTop = 0;
            }, 300)
          })
      );
    }
  }
}
</script>