/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    update_breadcrumb();
    update_product_list_select();
    update_search_variable_blocks_mob();

    $(window).resize(function() {
        update_breadcrumb();
        update_search_variable_blocks_mob();
    });

    init_pe_collections_swiper();
    init_pe_collections_index_swiper();
    init_pe_banner_banner_swiper();
    init_pe_banners_swiper();

    // init_content_links_swiper();
    init_destinations_top_swiper();
    init_product_list_gallery_swiper();
    init_product_offers_swiper();
    init_hotel_gallery_swiper();
    init_products_filters();
    init_products_gallery();
    init_similar_products_swiper();

    init_summary_dropdowns();

    init_main_header_sticky();
    init_footer_menu_collapse();

    $(document).on('click', '.offer-card .act-toggle-flight-info', function () {
        $(this).parent().parent().find('.info-collapsed').hide();
        $(this).parent().parent().find('.info-expanded').show();
    });

    $(document).on('click', '.act-scroll-to', function(e) {
        e.preventDefault();
        let target = $(this).data('target');

        $('html, body').animate({
            scrollTop: $(target).offset().top - 30
        }, 'slow');
    });

    $(document).on('click', '.act-copy-address', function(e) {
        e.preventDefault();

        let address = $(this).data('text');
        navigator.clipboard.writeText(address);
    })

    $(window).on('scroll', function(e) {
        // update_inquiry_destination_dropdown();
    });

    $(document).on('click', '.product-card a', function () {
        product_view = {
            'name':$(this).parents('.product-card').data('product-name'),
            'id':$(this).parents('.product-card').data('product-id'),
            'price':$(this).parents('.product-card').data('price'),
            'category':$(this).parents('.product-card').data('category')
        };
        window.enhancedEcomm('productClick', [product_view]);
    });

    init_product_map();

    if (window.location.hash) {
        var hash = window.location.hash;

        if (hash == '#uzklausa' && $('#contactUsModal').length) {
            $('#contactUsModal').modal('show');
        }
    }
    $('a[href$="#uzklausa"]').on('click', function(e) {
        e.preventDefault();

        if ($('#contactUsModal').length) {
            $('#contactUsModal').modal('show');
        }
    });
    // update_inquiry_destination_dropdown();
});

function update_search_variable_blocks_mob() {
    if ($('#mobileSearchDrawer').length) {
        let drawer = document.getElementById('mobileSearchDrawer');
        let gap = 8;
        let footer_h = 89;
        let block_h = drawer.querySelector('.destination-wrp.search-variable-block').offsetHeight;
        let window_h = document.documentElement.clientHeight;
        let offset_top = drawer.querySelector('.search-variables-wrp').offsetTop;
        let total_content_h = window_h - offset_top;

        let arr = drawer.querySelectorAll('.search-variable-block');
        arr.forEach(function (element, index, arr) {
            let max_height = total_content_h - (block_h * index) - (gap * index) - (gap * 2);
            if (Object.is(arr.length - 1, index)) {
                max_height -= footer_h;
            }
            element.style.maxHeight = max_height + "px";
        });
    }
}

function update_inquiry_destination_dropdown() {
    if ($('#inquiryDestinationField').length) {
        let field = document.getElementById('inquiryDestinationField');
        let offset = field.getBoundingClientRect();
        let dropdown_el = field.parentNode.querySelector('.custom-dropdown');

        let field_offset_top = offset.top;
        let field_height = field.offsetHeight;
        let window_height = window.screen.availHeight;

        let space_top = field_offset_top;
        let space_bottom = window_height - (field_offset_top + field_height);

        // console.log(offset, space_bottom);
        if (space_top > space_bottom) {
            dropdown_el.classList.remove('bottom');
            dropdown_el.classList.add('top');
        } else {
            dropdown_el.classList.add('bottom');
            dropdown_el.classList.remove('top');
        }
    }
}
function init_pe_collections_swiper() {
    if ($('.page-editor-type-collections_carousel').length) {
        $('.page-editor-type-collections_carousel').each(function () {
            let id = $(this).data('id');

            let swiper = new Swiper('.page-editor-type-collections_carousel[data-id="'+ id +'"] .swiper', {
                slidesPerView: "2.25",
                spaceBetween: 8,
                breakpoints: {
                    440: {
                        slidesPerView: "3.25",
                        spaceBetween: 8,
                    },
                    600: {
                        slidesPerView: "4.25",
                        spaceBetween: 8,
                    },
                    900: {
                        spaceBetween: 7,
                        slidesPerView: 5,
                    }
                }
            });
        });
    }
}

function init_pe_collections_index_swiper() {
    if ($('.collections_index_listing .inner-wrp.type-2').length) {
        $('.collections_index_listing .inner-wrp.type-2').each(function () {
            let id = $(this).parent().attr('id');

            let swiper = new Swiper('#' + id + ' .swiper', {
                slidesPerView: 1.2,
                spaceBetween: 12,
                breakpoints: {
                    560: {
                        slidesPerView: 2.2,
                    },
                    767: {
                        slidesPerView: 3.2,
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    }
                }
            });
        });
    }
}

function init_similar_products_swiper() {
    if ($('#similarProducts').length) {
        let swiper = new Swiper('#similarProducts .swiper', {
            slidesPerView: 1.2,
            spaceBetween: 12,
            breakpoints: {
                560: {
                    slidesPerView: 2.2,
                },
                767: {
                    slidesPerView: 3.2,
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                }
            }
        });
    }
}

function init_pe_banner_banner_swiper() {
    if ($('.page-editor-type-banner_banner').length) {
        $('.page-editor-type-banner_banner').each(function () {
            let id = $(this).data('id');
            let selector = '.page-editor-type-banner_banner[data-id="'+ id +'"]';

            let swiper = new Swiper(selector + ' .swiper', {
                slidesPerView: 1,
                spaceBetween: 16,
                pagination: {
                    el: selector + " .swiper-pagination",
                },
                navigation: {
                    enabled: true,
                    nextEl: selector + ' .swiper-button-next',
                    prevEl: selector + ' .swiper-button-prev',
                },
                breakpoints: {
                    767: {
                        slidesPerView: 2,
                        navigation: {
                            enabled: false,
                        },
                    },
                }
            });
        });
    }
}

function init_pe_banners_swiper() {
    if ($('.page-editor-type-banners').length) {
        $('.page-editor-type-banners').each(function () {
            let id = $(this).data('id');
            let selector = '.page-editor-type-banners[data-id="'+ id +'"]';
            let swiper = false;
            let swiper_options = {
                slidesPerView: 1,
                spaceBetween: 16,
                loop: true,
                nested: true,
                pagination: {
                    el: selector + ' .swiper-pagination',
                }
            }

            if (get_vw() < screen_md) {
                if (!swiper) {
                    swiper = new Swiper(selector + ' .swiper', swiper_options);
                }
            } else if (swiper) {
                swiper.destroy();
                swiper = false;
            }

            $(window).resize(function() {
                if (get_vw() < screen_md) {
                    if (!swiper) {
                        swiper = new Swiper(selector + ' .swiper', swiper_options);
                    }
                } else if (swiper) {
                    swiper.destroy();
                    swiper = false;
                }
            });
        })
    }
}

function init_product_list_gallery_swiper() {
    if ($('.product-card').length) {
        $('.product-card').each(function () {
            let id = $(this).data('id');
            let selector = '.product-card[data-id="'+ id +'"]';

            let swiper = new Swiper(selector + ' .swiper', {
                slidesPerView: 1,
                loop: true,
                nested: true,
                pagination: {
                    el: selector + ' .swiper-pagination',
                },
                navigation: {
                    nextEl: selector + ' .swiper-button-next',
                    prevEl: selector + ' .swiper-button-prev',
                },
            });
        });
    }
}

function init_product_offers_swiper() {
    if ($('.offers-listing').length) {
        $('.offers-listing').each(function () {
            let id = $(this).data('id');
            let selector = '.offers-listing[data-id="'+ id +'"]';

            let swiper = new Swiper(selector + ' .swiper', {
                slidesPerView: 1.2,
                spaceBetween: 12,
                navigation: {
                    enabled: false,
                    nextEl: selector + ' .swiper-button-next',
                    prevEl: selector + ' .swiper-button-prev',
                },
                breakpoints: {
                    767: {
                        slidesPerView: 2.2,
                        spaceBetween: 14,
                        navigation: {
                            enabled: false,
                        },
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                        navigation: {
                            enabled: true,
                        },
                    }
                }
            })
        })
    }
}

function init_hotel_gallery_swiper() {
    if ($('.hotel-gallery-wrp').length) {
        $('.hotel-gallery-wrp').each(function () {
            let id = $(this).data('id');
            let selector = '.hotel-gallery-wrp[data-id="'+ id +'"]';

            let swiper = new Swiper(selector + ' .swiper', {
                slidesPerView: 2.04,
                spaceBetween: 8,
                navigation: {
                    enabled: false,
                    nextEl: selector + ' .swiper-button-next',
                    prevEl: selector + ' .swiper-button-prev',
                },
                breakpoints: {
                    767: {
                        slidesPerView: 2.2,
                        spaceBetween: 14,
                        navigation: {
                            enabled: false,
                        },
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                        navigation: {
                            enabled: true,
                        },
                    }
                }
            })
        })
    }
}

function init_content_links_swiper() {
    if ($('.content-links-wrp .swiper').length) {
        $('.content-links-wrp .swiper').each(function () {
            let id = $(this).data('id');
            let selector = '.content-links-wrp .swiper[data-id="'+ id +'"]';

            let swiper = new Swiper(selector, {
                slidesPerView: "auto",
                spaceBetween: 8,
                freeMode: true,
            });
        })
    }
}

function init_destinations_top_swiper() {
    if ($('.top-destinations-wrp').length) {
        $('.top-destinations-wrp').each(function () {
            let id = $(this).attr('id');

            let swiper = new Swiper('#' + id + ' .swiper', {
                slidesPerView: 1.55,
                spaceBetween: 8,
                breakpoints: {
                    430: {
                        slidesPerView: 2.55,
                    },
                    600: {
                        slidesPerView: 3.55,
                    },
                    767: {
                        slidesPerView: 4.55,
                    },
                    992: {
                        slidesPerView: 5,
                        spaceBetween: 16,
                    }
                }
            });
        });
    }
}

function init_summary_dropdowns() {
    $(document).on('click','.summary-dropdown .summary-dropdown-title', function () {
        $(this).parent().toggleClass('open');
    });
}

function init_products_filters() {
    // filter groups dropdowns - desktop
    window.addEventListener('click', function(e) {
        let filter_group_el = e.target.closest('.filter-group-wrp');

        // if (get_vw() > screen_md_min) {
        //     if (filter_group_el == null || !filter_group_el.classList.contains('open')) {
        //         document.querySelectorAll('.filter-group-wrp').forEach(function (el, index) {
        //             el.classList.remove('open');
        //
        //             if (productFilter.latest_hash != productFilter.filterHash) {
        //                 productFilter.reload();
        //             }
        //         })
        //     }
        //
        //     if (e.target.classList.contains('filter-group-title')) {
        //         if (e.target.parentNode.classList.contains('open')) {
        //             if (productFilter.latest_hash != productFilter.filterHash) {
        //                 productFilter.reload();
        //             }
        //         }
        //
        //         e.target.parentNode.classList.toggle('open');
        //     }
        // }
    })
}

function init_product_map() {
    let map;

    async function initMap() {
        // The location of Uluru
        const map_el = document.getElementById('map');

        if (map_el.dataset.multiple) {
            var locations = window.map_markers;
        } else {
            var locations = [
                { title: map_el.dataset.title, lat: parseFloat(map_el.dataset.latitude), lng: parseFloat(map_el.dataset.longitude) }
            ];
        }

        // Request needed libraries.
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
        const bounds = new google.maps.LatLngBounds();

        // The map, centered at Uluru
        let map_center = (locations.length > 1 ? { lat: 0, lng: 0 } : { lat: locations[0].lat, lng: locations[0].lng });
        map = new Map(map_el, {
            zoom: 10,
            center: map_center,
            mapId: "KPANORAMA_MAP_ID",
        });

        const parser = new DOMParser();
        // A marker with a custom inline SVG.
        const pinSvgString =
            '<svg width="60" height="60" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M43.3242 75.5156C41.5898 77.6836 38.2656 77.6836 36.5312 75.5156C29.1602 66.1211 12.25 43.7188 12.25 31C12.25 15.6797 24.6797 3.25 40 3.25C55.3203 3.25 67.75 15.6797 67.75 31C67.75 43.7188 50.8398 66.1211 43.3242 75.5156ZM40 21.75C36.6758 21.75 33.6406 23.6289 31.9062 26.375C30.3164 29.2656 30.3164 32.8789 31.9062 35.625C33.6406 38.5156 36.6758 40.25 40 40.25C43.1797 40.25 46.2148 38.5156 47.9492 35.625C49.5391 32.8789 49.5391 29.2656 47.9492 26.375C46.2148 23.6289 43.1797 21.75 40 21.75Z" fill="#B5A7FC"/>\n' +
            '</svg>';
        const pinSvg = parser.parseFromString(
            pinSvgString,
            "image/svg+xml",
        ).documentElement;

        for (i = 0; i < locations.length; i++) {
            let position = {lat: parseFloat(locations[i].lat), lng: parseFloat(locations[i].lng)};
            const marker = new AdvancedMarkerElement({
                map: map,
                position: position,
                content: pinSvg,
                title: locations[i].title,
            });

            bounds.extend(marker.position);
        }
        if (locations.length > 1) {
            map.fitBounds(bounds);
        }
    }

    if ($('.contacts-page #map').length) {
        initMap();
    }
    $(document).on('click', '.act-load-map', function(e) {
        e.preventDefault();
        $('#map').addClass('initialized');

        initMap();
    });
}

function init_products_gallery() {
    let buttons = document.querySelectorAll('.act-toggle-gallery');
    let to_prices_btn = document.querySelector('.act-gallery-prices');

    buttons.forEach(function (button) {
        button.addEventListener('click', function (e) {
            document.querySelector('body').classList.toggle('product-gallery-active');
            document.querySelector('.photos-gallery .gallery-wrp').classList.toggle('active');
        })
    });

    if (to_prices_btn) {
        to_prices_btn.addEventListener('click', function (e) {
            document.querySelector('body').classList.toggle('product-gallery-active');
            document.querySelector('.photos-gallery .gallery-wrp').classList.toggle('active');

            $('html, body').animate({
                scrollTop: $('#inner_search').offset().top - 30
            }, 'slow');
        })
    }
}

function init_main_header_sticky() {
    var header = document.getElementById('mainHeader');
    var header_state = 'normal'; // sticked, pinned
    var header_height_mob = 300;
    var last_scroll = 0;

    if (header) {
        $(window).on('scroll', function () {
            let toTop = $(document).scrollTop();
            let direction = (last_scroll < toTop ? 'down' : 'up');
            last_scroll = toTop;

            if (toTop > header_height_mob) {
                header.classList.add('pinned');

                if (direction === 'down' && header_state != 'pinned') { // paslepiam
                    header_state = 'pinned';
                    header.classList.remove('sticked');
                } else if (direction === 'up' && header_state != 'sticked') { // rodom
                    header_state = 'sticked';
                    header.classList.add('sticked');
                }
            } else {
                header.classList.remove('pinned', 'sticked');
            }
        });
    }
}
function init_footer_menu_collapse() {
    if ($('#pages_footer_menu').length) {
        if (get_vw() < screen_md) {
            $('#pages_footer_menu .menu-column.has-items .column-title').on('click', function () {
                $(this).parent().toggleClass('open');
            });
        }
    }
}

window.contact_us_form_submit = function(el, event) {
    var url = el.action ? el.action : '';
    var method = el.method ? el.method : '';
    var template = el.dataset.template;
    var html_container = el.dataset.container;

    event.preventDefault();

    $.ajax({
        url: url + (!url.match(/\?/) ? '?' : (!url.match(/&$/) ? '&' : '')) + 'display=' + template,
        method: method,
        data: $(el).serialize(),
        success: function(data, textStatus, jqXHR) {
            $('#ajax_loader').hide();

            var content = $(data);

            $(html_container).each(function() {
                $(this).html(content.find(html_container).html());
            });
        },
        error: function(data) {
            $('#ajax_loader').hide();
        },
        beforeSend: function() {
            $('#ajax_loader').show();
        }
    })
}

window.newsletter_form_submit = function(el, event) {
    var url = el.action ? el.action : '';
    var method = el.method ? el.method : '';
    var template = el.dataset.template;
    var html_container = el.dataset.container;

    event.preventDefault();

    $.ajax({
        url: url + (!url.match(/\?/) ? '?' : (!url.match(/&$/) ? '&' : '')) + 'display=' + template,
        method: method,
        data: $(el).serialize(),
        success: function(data, textStatus, jqXHR) {
            $('#ajax_loader').hide();

            var content = $(data);

            $(html_container).each(function() {
                let new_form_id = 'newsletter_form' + Math.random().toString(36).substr(2, 16);
                let new_marketing_id = 'newsMarketing' + Math.random().toString(36).substr(2, 16);

                content.find('form').attr('id', new_form_id);
                content.find('[type="checkbox"][name="marketing_agreed"]').attr('id', new_marketing_id);
                content.find('[type="checkbox"][name="marketing_agreed"] + label').attr('for', new_marketing_id);

                $(this).html(content.find(html_container).html());
            });
        },
        error: function(data) {
            $('#ajax_loader').hide();
            // console.log('error', data);
        },
        beforeSend: function() {
            $('#ajax_loader').show();
        }
    });
}