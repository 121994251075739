<template>
  <VueDatePicker v-model="date"
                 :flow="flow"
                 :enable-time-picker="false"
                 :min-date="new Date()"
                 :hide-input-icon=true
                 :year-range="[currentYear, currentYear + 11]"
                 data-theme="theme-1"
                 auto-apply
                 month-name-format="long"
                 format="yyyy-MM-dd"
                 locale="lt">
    <template #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
      <div class="form-group floating-label" :class="has_error ? 'is-invalid' : ''" ref="dateInputField">
        <label for="field-date">{{ t('Kelionės data') }}</label>
        <input  :value="value" type="text"
               class="form-control"
               placeholder="{{ t('DD/MM/MMMM') }}"
               name="date" id="field-date" autocomplete="off">
        <span class="error-message">{{t('Pasirinkite datą')}}</span>
      </div>

    </template>
  </VueDatePicker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import {ref} from 'vue';

export default {
  components: {VueDatePicker},
  props: ['has_error'],
  data() {
    return {
      date: null,
      flow: ref(['year', 'month', 'calendar']),
      dateInputField: null,
      currentYear: new Date().getFullYear(),
    }
  },
  mounted() {
    this.dateInputField = this.$refs.dateInputField.classList;
  }
}
</script>