(function($) {
    $.fn.inquiryForm = function(options) {
        var defaults = {
            form_selector: '#quickInquiryForm',
            step_container_selector: '.form-step-wrp',
            next_step_selector: '.act-next-step',
            current_step: 1,
            total_steps: 5,
            return_selector: '#inquiryHeader .return-link',
        };

        var settings = $.extend({}, defaults, options);
        var form = document.querySelector(settings.form_selector);
        let currentFormStep = settings.current_step;
        // let currentFormStep = 4;

        var init = function() {
            document.querySelectorAll(settings.next_step_selector).forEach(step_btn => {
                step_btn.addEventListener("click", function() {
                    if (validateStep(currentFormStep)) { // validate
                        currentFormStep++;
                        updateStepVisibility();
                    } else {
                        console.log('invalid');
                    }
                });
            });

            document.querySelector(settings.return_selector).addEventListener('click', function(e) {
                e.preventDefault();

                currentFormStep--;
                updateStepVisibility();
            })
        }

        var updateStepVisibility = function() {
            form.querySelectorAll(settings.step_container_selector).forEach(function(step) {
                step.style.display = 'none';
            });

            form.querySelector(settings.step_container_selector + '[data-step="' + currentFormStep + '"]').style.display = 'block';

            // update nav link
            if (currentFormStep == 1) {
                document.querySelector(settings.return_selector).style.display = 'none';
            } else {
                document.querySelector(settings.return_selector).style.display = 'inline-block';
            }
        }

        var validateStep = function(formStep) {
            let isValid = true;

            if (formStep === 1) {
                let step_fields = {
                    first_name: 'required',
                    last_name: 'required',
                    email: 'email|required',
                    phone: 'phone|required',
                    privacy_aggree: 'checkbox|required',
                }

                for (const [field_name, rules] of Object.entries(step_fields)) {
                    if (!validateField(field_name, rules)) {
                        isValid = false;
                    }
                }
            } else if (formStep === 2) {
                let destinations_count = form.querySelectorAll('[name="categories[]"]').length;
                let field = document.querySelector('.destination-inquiry-wrp .fake-field');

                if (destinations_count == 0) {
                    field.classList.add('is-invalid');
                    isValid = false;
                } else {
                    field.classList.remove('is-invalid');
                }
            } else if (formStep === 3) {
                let nights_fake_field = document.querySelector('.nigths-input');
                let date_fake_field = document.querySelector('.date-input');
                let date_field = document.querySelector('[name="date_from"]');
                let tab = document.querySelector('[name="tab"]')
                let nights_from = document.querySelector('[name="nights_from"]');
                let nights_to = document.querySelector('[name="nights_to"]');
                
                if ((date_field.value === false || date_field.value.length === 0)) {
                    date_fake_field.classList.add('is-invalid');
                    isValid = false;
                } else {
                    date_fake_field.classList.remove('is-invalid');
                }
                
                if ((nights_from.value === false || nights_from.value.length === 0) || (nights_to.value === false || nights_to.value.length === 0)) {
                    nights_fake_field.classList.add('is-invalid');
                    isValid = false;
                } else {
                    date_fake_field.classList.remove('is-invalid');
                }
                
            } else if (formStep === 4) {

            }

            return isValid;
        }

        var validateField = function(field_name, rules_raw) {
            var email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            let rules = rules_raw.split('|');
            let input = form.querySelector('[name="'+ field_name +'"]');
            let value = false;

            if (rules.includes('checkbox')) {
                value = form.querySelector('[name="'+ field_name +'"]').checked;
            } else {
                value = form.querySelector('[name="'+ field_name +'"]').value;
            }

            if (rules.includes('required')) {
                if (value.length === 0 || value === false) {
                    showError(input, settings.messages.empty);
                    return false;
                } else {
                    clearError(input);
                }
            }

            if (rules.includes('email')) {
                if (!email_regex.test(value)) {
                    showError(input, settings.messages.wrong_format);
                    return false;
                } else {
                    clearError(input);
                }
            }

            if (rules.includes('phone')) {
                let phone_regex = window.phone_regex;

                if (!phone_regex.test(value)) {
                    showError(input, settings.messages.wrong_format);
                    return false;
                } else {
                    clearError(input);
                }
            }

            // console.log(input);
            let isValidField = true;
            let shouldSkip = false;
            rules.forEach(function(rule) {
                if (shouldSkip) {
                    return;
                }

                if (rule.includes('lenght_min')) {
                    let [r_name, r_value] = rule.split(':');

                    if (value.length < r_value) {
                        showError(input, settings.messages.too_short);
                        return;
                    } else {
                        clearError(input);
                    }
                }
            });

            return isValidField;
        }

        var showError = (input, message) => {
            let parent = input.parentNode;
            // console.log(input);
            // const errorSpan = formControl.querySelector(".error-message");
            parent.classList.add("is-invalid");
            input.setAttribute("aria-invalid", "true");

            if (parent.classList.contains('form-group')) {
                let span = parent.querySelector('.error-message');

                if (span == null) {
                    span = document.createElement("span");
                }

                span.classList.add("error-message");
                span.innerText = message;

                parent.appendChild(span);
            }
            // input.setAttribute("aria-describedby", errorSpan.id);
            // errorSpan.textContent = message;
            return;
        };

        var clearError = (input) => {
            let parent = input.parentNode;
            // const formControl = input.parentElement;
            // const errorSpan = formControl.querySelector(".error-message");
            parent.classList.remove("is-invalid");
            input.removeAttribute("aria-invalid");

            let span = parent.querySelector('.error-message');
            if (span != null) {
                parent.querySelector('.error-message').remove();
            }
            // input.removeAttribute("aria-describedby");
            // errorSpan.textContent = "";
        };


        init();
    }
}( jQuery ));