<template>
  <div class="destination-options-search-field-wrp">
    <div class="fake-field">
      <span v-if="resolution == 'mobile'" class="act-close-destination-drawer" role="button">
        <i class="fa-regular fa-arrow-left-long"></i>
      </span>
      <span v-else>
        <i class="fa-solid fa-magnifying-glass"></i>
      </span>
      <input type="text" name="destination-search-field" :placeholder="t('Ieškoti šalies, miesto, viešbutis')" v-model="searchString">
    </div>
  </div>

  <div class="destination-options-wrp custom-scrollbar">
    <div class="options-group">
      <span class="group-title">{{ t('Kryptys') }}</span>
      <div class="group-items-wrp">
          <single-destination  v-for="(category,key) in destinations.categories" :destination="category" :search="searchString" :is-hotel="false">
          </single-destination>
      </div>
    </div>

    <div class="options-group">
      <span class="group-title">{{ t('Viešbučiai') }}</span>
      <div class="group-items-wrp">
        <single-destination  v-for="(hotel,key) in destinations.hotels" :destination="hotel" :search="searchString" :is-hotel="true">
        </single-destination>
      </div>
    </div>
  </div>
</template>


<script>
import {destinations} from "../reactives/destinations"

export default {
  props: ['resolution'],
  data() {
    return {
      destinations,
      searchString: '',
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>