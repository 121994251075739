<template>
  <div id="offerDetailsModal" class="modal fade slide-up offer-details-modal" tabindex="-1" role="dialog"
       aria-labelledby="offerModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center">{{t('Pasiūlymo detalės')}}</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="!loading && book_data !== null && !expired">
          <div class="offer-summary-wrp">
            <div class="summary-top">
              <div class="photo-wrp" v-html="product.photo">
              </div>
              <div class="info-wrp">
                <span class="name">{{ product.hotel }}</span>
                <span class="location">{{ product.country }}, {{ product.city }}</span>
              </div>
            </div>
            <div class="summary-bottom">
              <div class="price-wrp">
                <div class="person-price-wrp">
                  <span class="price">{{ offer.person_price_formatted}}</span>
<!--                  <span class="old-price">{550|fprice}</span>-->
                  <span class="price-prefix">{{t('/asm.')}}</span>
                </div>
                <div class="total-price-wrp">
                  <span class="total-price">{{ offer.price_formatted}}</span>
                  <span class="price-prefix">{{t('visiems')}}</span>
                </div>
                <div class="advance-price-wrp">
                  <span class="advance">{{t('Mokėk dabar tik')}} {{ advance_data.price_formatted}}</span>
                </div>
              </div>
              <div class="travel-dates-wrp">
                <span class="dates">{{ offer.departure_date }} - {{ offer.return_date }}</span>
              </div>
            </div>
            <div class="actions-wrp">
              <button class="btn btn-primary w-100" @click="submitBooking()">
                {{t('Užsakyti')}} <i class="fa-solid fa-chevron-right ms-3 mb-n1"></i>
              </button>
            </div>
          </div>

          <div class="room-photos-wrp">
            <div v-for="photo in photos" class="photo-el" v-html="photo">
            </div>
            <div v-show="mainPhoto" class="photo-el big" v-html="mainPhoto">
            </div>
          </div>

          <div class="room-details-wrp">
            <div class="block-title">{{t('Kambarys')}}</div>
            <div class="features-list-wrp list-wt-round-icons">
              <div class="list-el">
                <i class="fa-light fa-bed"></i>
                <span class="value">{{offer.hotel_room_name}}</span>
              </div>
              <div class="list-el">
                <i class="fa-light fa-moon"></i>
                <span class="value">{{offer.duration}} {{ str_by_number(offer.duration,[t('naktis'),t('naktys'),t('naktų')]) }}</span>
              </div>
              <div class="list-el">
                <i class="fa-light fa-utensils"></i>
                <span class="value">{{offer.pansion.name}}</span>
              </div>
            </div>
          </div>

          <div class="flights-info-wrp">
            <h3 class="block-title">{{ flight_data.forward_airports }}</h3>
            <div class="flights-list-wrp">
              <div class="single-flight-wrp">
                <div class="flight-line departure">
												<span class="name">
													<i class="fa-light fa-plane-departure"></i>
													<span>{{t('Išvykimas')}}</span>
												</span>
                  <span class="value">{{ flight_data.forward.departureTime }}</span>
                </div>
                <div class="flight-line arrival">
												<span class="name">
													<i class="fa-light fa-plane-arrival"></i>
													<span>{{t('Atvykimas')}}</span>
												</span>
                  <span class="value">{{ flight_data.forward.arrivalTime }}</span>
                </div>
                <div class="flight-line duration">
												<span class="name">
													<i class="fa-light fa-clock"></i>
													<span>{{t('Kelionės trukmė')}}</span>
												</span>
                  <span class="value">{{ flight_data.forward_duration }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flights-info-wrp no-border">
            <h3 class="block-title">{{ flight_data.return_airports }}</h3>
            <div class="flights-list-wrp">
              <div class="single-flight-wrp">
                <div class="flight-line departure">
												<span class="name">
													<i class="fa-light fa-plane-departure"></i>
													<span>{{t('Išvykimas')}}</span>
												</span>
                  <span class="value">{{ flight_data.return.departureTime }}</span>
                </div>
                <div class="flight-line arrival">
												<span class="name">
													<i class="fa-light fa-plane-arrival"></i>
													<span>{{t('Atvykimas')}}</span>
												</span>
                  <span class="value">{{ flight_data.return.arrivalTime }}</span>
                </div>
                <div class="flight-line duration">
												<span class="name">
													<i class="fa-light fa-clock"></i>
													<span>{{t('Kelionės trukmė')}}</span>
												</span>
                  <span class="value">{{ flight_data.return_duration }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="offer-included-wrp">
            <h3 class="block-title big">{{t('Kas įeina į pasiūlymą?')}}</h3>
            <div v-html="decodeHtml(product.include)">

            </div>
<!--            <ul class="round-checkmark-list type-2">-->
<!--            </ul>-->
          </div>

          <div class="order-terms-wrp">
            <h3 class="block-title big">{{t('Užsakymo sąlygos')}}</h3>
            <div class="desc-wrp" v-html="decodeHtml(terms)">
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="loading">
          <offer-modal-loader></offer-modal-loader>
        </div>
        <div class="modal-body" v-if="!loading && expired">
          <offer-modal-expired></offer-modal-expired>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OfferModalLoader from "./offerModalLoader.vue";
import OfferModalExpired from "./offerModalExpired.vue";

export default {
  components: {OfferModalExpired, OfferModalLoader},
  props: ['offer','product'],
  data() {
    return {
      loading: false,
      pid: false,
      priceInfo: false,
      book_data: null,
      flight_data: {},
      price_includes: [],
      terms: '',
      advance_data: {},
      expired: false,
      timer: null,
      mainPhoto: null,
      photos: null,
    }
  },
  watch: {
    offer(newVal,oldVal) {
      this.pid = false;
      this.priceInfo = false;
      this.expired = false;
      this.timer = null;
      this.loadOfferDetails();
    }
  },
  mounted() {
  },
  updated: function () {

  },
  methods: {
    loadOfferDetails() {
      let component = this;
      component.loading = true;
      $.ajax({
        type: 'GET',
        url: '?display=content_types/products/travel_option_details.tpl',
        data: {
          operator: this.offer.operator,
          departure: this.offer.departure_date,
          duration: this.offer.duration,
          adults: this.offer.adults,
          children: this.offer.children,
          children_ages: this.offer.children_ages,
          departure_airport: this.offer.departure_airport,
          product: this.product.id,
          pansion: this.offer.pansion.id,
          room: this.offer.hotel_room_name,
          price: this.offer.price,
          price_info: this.priceInfo?1:0,
          pid: this.pid===false?0:this.pid
        },
        success: function (data) {
          if(typeof data.background !== 'undefined') {
            if(component.pid !== false) {
              if(component.pid !== parseInt(data.pid)) {
                component.priceInfo = true;
              }
            }
            component.pid = data.pid;
            component.timer = setTimeout(function () {
              component.loadOfferDetails();
            }, 1000);
          } else {
            component.pid = false;
            component.priceInfo = false;
            component.loading = false;
            if (typeof data.status === 'undefined' && typeof data.book_data !== 'undefined') {
              component.book_data = data.book_data;
              component.flight_data = data.flight_data;
              component.price_includes = data.price_includes;
              component.advance_data = data.advance_data;
              component.terms = data.terms;
              component.mainPhoto = data.main_photo;
              component.photos = data.photos;
            } else {
              component.expired = true;
            }
          }
        }
      })
    },
    submitBooking() {
      let component = this;
      component.loading = true;
      const productData = {
        'name':this.product.name,
        'id':this.product.id,
        'price':this.offer.price,
        //'brand':this.book_data.operator,
        'quantity': 1,
      };
      window.enhancedEcomm('addToCart', [productData]);
      $.ajax({
        type: 'POST',
        url: '?display=content_types/products/submit_booking.tpl',
        data: {
          operator: this.offer.operator,
          product: this.product.id,
          search_data: this.book_data,
          adults: this.offer.adults,
          children: this.offer.children,
        },
        success: function (data) {
          if (typeof data.redirect !== 'undefined') {
            window.enhancedEcomm('begin_checkout', [productData]);
            window.location =data.redirect;
          } else {
            component.loading = false;
            component.expired = true;
          }
        }
      });
    }
  }
}
</script>