<template>
  <mobile-field ref="mobileSearchButton"></mobile-field>
  <desktop-search ref="desktopSearchForm" :durations="this.durations" :standalone="this.standalone" :url="this.url"></desktop-search>
</template>

<script>
import {travel} from "./reactives/travel";
import {destinations} from "./reactives/destinations";
import MobileField from "./travel/mobileField.vue";
import MobileDrawer from "./travel/mobileDrawer.vue";
import DesktopSearch from "./travel/desktopSearch.vue";

export default {
  components: {DesktopSearch, MobileDrawer, MobileField},
  props: ['title', 'standalone', 'url'],
  data() {
    return {
      airports: [],
      destinations,
      durations: [],
      travel
    }
  },
  mounted() {
    this.loadTravelOptions();
  },
  methods: {
    loadTravelOptions() {
      let component = this;
      $.ajax({
        type: 'GET',
        url: '?display=content_types/products/travel_options.tpl',
        data: {},
        success: async function (data) {
          await data.categories.forEach(function (cat) {
            component.destinations.pushCategory(cat, component.travel.destination);
          });
          await data.hotels.forEach(function (hotel) {
            component.destinations.pushHotel(hotel, component.travel.hotel);
          });
          component.travel.loaded = true;
          //component.airports = data.airports;
          //component.categories = data.categories;
          //component.durations = data.durations;
        }
      });
    },
    submitSearch() {
      if (parseInt(this.standalone) === 1) {
        window.location.replace(this.url+ '?' + decodeURIComponent($.param({
          travel: this.travel.prepareForRequest(Object.keys(this.destinations.activeCategories),Object.keys(this.destinations.activeHotels))
        })))
      } else {
        this.travel.search = true;
      }
    }
  }
}
</script>
