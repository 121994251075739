<template>
  <div class="input-group"><label for="departure_date" class="input-group-text d-flex justify-content-center"
                                  style="min-width:49px;"><i class="fa fa-calendar"></i></label>
    <input type="button" id="departure_date" class=" form-control text-start ps-4 rounded-end" data-bs-toggle="dropdown"
           data-bs-auto-close="outside"
           aria-expanded="false"
           readonly/>
<!--           :value="displayValue" -->
    <div class="dropdown-menu" style="min-width:700px;">
      <dates-content :durations="this.durations"></dates-content>
    </div>
  </div>

</template>

<script>
import {travel} from "../reactives/travel";
import DatesContent from "./datesContent.vue";

export default {
  components: {DatesContent},
  props: ['durations'],
  emits: [],
  data() {
    return {
      travel,
    }
  },
  computed: {
    displayValue() {
      if (this.travel.tab === 'date') {
        if (travel.dates.from.length > 0) {
          return travel.dates.from + this.t(' iki ') + travel.dates.to;
        } else {
          return this.t('Išvykimo data');
        }
      } else {
        if (typeof travel.year !== 'undefined' && travel.year.length > 0) {
          return travel.year + ' ' + this.years[travel.year][travel.month];
        } else {
          return this.t('Išvykimo data');
        }
      }
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
