import { createApp } from "vue/dist/vue.esm-bundler";
import ClickOutside from "./components/directives/ClickOutsideDirective";
import { vue_methods } from "./methods";


const app = createApp({
    el: '#main-app',
    setup() {

    },
    beforeMount() {

    },
    methods: {

    }
})

app.directive('click-outside', ClickOutside);

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))

app.directive('clickOutside',{
    mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value(binding.arg)
            }
        }
        document.addEventListener("click", el.clickOutsideEvent)
    },
    unmounted: function (el) {
        document.removeEventListener("click", el.clickOutsideEvent)
    },
});

app.mixin({
    methods: vue_methods,
});

app.config.warnHandler = (msg, instance, trace) => {
    //Suppress template errors for inline script tags
    if(msg.includes("Template compilation error: Tags with side effect")) {
        return;
    }
    console.warn(msg, instance, trace);
}

app.mount('#main-app');