<template>
  <div id="inquiry-dates-block" class="" v-click-outside="closeSearchDropdown">
    <div class="inner-wrp">
      <input type="hidden" name="tab" v-model="travel.tab" />
      <input type="hidden" name="flex" v-model="travel.flex" />
      <input type="hidden" name="date_from" v-model="travel.date.from" />
      <input type="hidden" name="date_to" v-model="travel.date.to" />
      <input type="hidden" name="nights_from" v-model="travel.duration.from"  />
      <input type="hidden" name="nights_to" v-model="travel.duration.to"  />
      
      <div class="fields-wrp d-none d-lg-block">
        <div class="dates-wrp inquiry-variable-block" :class="activeVariable == 'dates' ? 'active' : ''">
          
          <div class="summary-wrp" @click="toggleSearchDropdown('dates')">
            <div class="form-group floating-label date-input" :class="has_error ? 'is-invalid' : ''">
              <label for="field-date">{{t('Kelionės data')}}</label>
              <input type="text" class="form-control" placeholder="{{t('Bet kada')}}" id="field-date"  v-model="travel.activeDateText" autocomplete="off">
            </div>
          </div>

          <div class="custom-dropdown"> 
            <div class="inner-wrp">
              <dates-content resolution="desktop"></dates-content>
            </div>
          </div>
        </div>
        <div class="nights-wrp inquiry-variable-block" :class="activeVariable == 'nights' ? 'active' : ''">
          <div class="summary-wrp nigths-input" @click="toggleSearchDropdown('nights')">
            <span class="title">{{ t('Naktų skaičius') }}</span>
            <span class="value">{{ nights_str(travel.duration.from,travel.duration.to) }}</span>
          </div>
  
          <div class="custom-dropdown">
            <div class="inner-wrp">
              <span class="nights-title">{{ t('Kelionės trukmė') }}</span>
              <nights-content></nights-content>
            </div>
          </div>
        </div>
      </div>
      <div class="fields-wrp d-md-block d-lg-none">
        
        <div id="inquiryModalNights" class="inquiryModal" aria-modal="true">
          <div class="inner-wrp" tabindex="-1" role="presentation">
            <div class="header-wrp">
              <button class="modal-close-btn nights" :aria:lavel="t('Uždaryti')">
                <i class="fa-regular fa-xmark"></i>
              </button>
            </div>
            <div class="content-wrp">
              <div class="search-variables-wrp">
                <div class="nights-wrp search-variable-block active">
                  <div class="variable-content-wrp">
                    <div class="variable-inner-wrp">
                      <nights-content></nights-content>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div id="inquiryModalDates" class="inquiryModal" aria-modal="true">
          <div class="inner-wrp" tabindex="-1" role="presentation">
            <div class="header-wrp">
              <button class="modal-close-btn dates" :aria:lavel="t('Uždaryti')">
                <i class="fa-regular fa-xmark"></i>
              </button>
            </div>
            <div class="content-wrp">
              <div class="search-variables-wrp">
                <div class="dates-wrp search-variable-block active">
                  <div class="variable-content-wrp">
                    <div class="variable-inner-wrp">
                      <dates-content resolution="mobile"></dates-content>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="dates-wrp inquiry-variable-block">
          <div class="summary-wrp" @click="openInquiryModal('dates')">
            <div class="form-group floating-label">
              <label for="field-date">{{t('Kelionės data')}}</label>
              <input type="text" class="form-control" placeholder="{{t('Bet kada')}}" id="field-date"  v-model="travel.activeDateText" autocomplete="off">
            </div>
          </div>

          <div class="custom-dropdown">
            <div class="inner-wrp">
              <dates-content resolution="desktop"></dates-content>
            </div>
          </div>
        </div>

        
        
        <div class="nights-wrp inquiry-variable-block">
          <div class="summary-wrp" @click="openInquiryModal('nights')">
            <span class="title">{{ t('Naktų skaičius') }}</span>
            <span class="value">{{ nights_str(travel.duration.from,travel.duration.to) }}</span>
          </div>

          <div class="custom-dropdown">
            <div class="inner-wrp">
              <span class="nights-title">{{ t('Kelionės trukmė') }}</span>
              <nights-content></nights-content>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import DatesContent from "../travel/datesContent.vue";
import NightsContent from "../travel/nightsContent.vue";
import {travel} from "../reactives/travel";

export default {
  components: {NightsContent, DatesContent},
  props: ['standalone', 'url', 'durations', 'has_error'],
  data() {
    return {
      destinationsDrawer: false,
      activeVariable: false,
      desktopSearchForm: false,
      travel,
      // mainMenu: window.main_menu,
    }
  },
  mounted() {
    let component = this;
    component.desktopSearchForm = document.getElementById("desktopSearchForm");

    document.querySelector('.modal-close-btn.dates').addEventListener('click', (e) => {
      e.preventDefault();
      
      component.closeInquiryModal('dates');
    });

    document.querySelector('.modal-close-btn.nights').addEventListener('click', (e) => {
      e.preventDefault();

      component.closeInquiryModal('nights');
    });
    
    this.nightsModal = document.getElementById("inquiryModalNights");
    this.datesModal = document.getElementById("inquiryModalDates");
    
    // this.destinationsDrawer = document.querySelector(".destinations-drawer");
    // document.querySelectorAll('.act-open-mob-destination, .destination-wrp.search-variable-block .summary-wrp').forEach(function (el) {
    //   el.addEventListener('click', (e) => {
    //     component.openDestinationsDrawer();
    //   });
    // })


    // document.querySelector('.act-close-destination-drawer').addEventListener('click', (e) => {
    //   this.closeDestinationsDrawer();
    // })
  },
  methods: {
    closeSearchDropdown() {
      this.activeVariable = false;
    },
    toggleSearchDropdown(type) {
      if (this.activeVariable == type) {
        this.activeVariable = false;
      } else {
        this.activeVariable = type;
      }
    },
    openInquiryModal: function(type) {
      let component = this;

      if (type == 'dates') {
        component.datesModal.classList.add("open");
        
      } else if (type == 'nights') {
        component.nightsModal.classList.add("open");
      }
      
      document.querySelector('body').classList.add('locked');
    },
    closeInquiryModal: function(type) {
      let component = this;

      if (type == 'dates') {
        component.datesModal.classList.remove("open");

      } else if (type == 'nights') {
        component.nightsModal.classList.remove("open");
      }
    },
  }
}
</script>